import { Box, Grid, Typography, IconButton } from "@mui/material";
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import FavoriteIcon from "@mui/icons-material/Favorite";
import dimonds from "../../../assests/diamands.png";
import LockIcon from "@mui/icons-material/Lock";
import jems from "../../../component/Images/jems.png";
// import "./HomeFeed.scss";
import ModelProfilePicture from "../../../assests/ic_login1.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../User/Photopage/Photopage.scss";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";

function Profile_view({ data, paginate, lastDoc }) {
  const navigate = useNavigate();
  return (
    <Box
      className="photomain1"
      id="scrollList"
      style={{ height: "100%", overflowY: "scroll" }}
    >
      <InfiniteScroll
        dataLength={Object.keys(data).length}
        next={() => {
          paginate(lastDoc);
        }}
        hasMore={true}
        scrollableTarget="scrollList"
      >
        {Object.values(data).map((ele) => {
          return (
            <Box>
              <Grid container className="photodiv">
                <Grid item className="photodiv_imgdiv">
                  <div className="profilepic">
                    <img
                      alt=""
                      src={ModelProfilePicture}
                      className="photoimg"
                    />
                  </div>
                </Grid>
                <Grid item className="profiletext">
                  <Typography
                    variant="body1"
                    className="text-align-start"
                    id="photousername"
                  >
                    {process.env.REACT_APP_MODAL_NAME}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="text-align-start"
                    id="photocaption"
                  >
                    {ele.desc}
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Box
                  className="postimgdiv"
                  onClick={() =>
                    navigate("/back/updatephoto", {
                      state: { photoobject: ele },
                    })
                  }
                >
                  <Box className="postdiv">
                    <img alt="" src={ele.photoUrl} className="postimg" />
                    {ele.noOfGems > 0 ? (
                      <Box className="photoLock">
                        <LockIcon
                          style={{ fontSize: "18px", marginRight: "2px" }}
                        />
                        <Typography variant="body2">Paid Photo</Typography>
                      </Box>
                    ) : null}
                  </Box>

                  <Grid
                    container
                    className="photolikediv"
                    style={{ marginTop: "10px" }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton>
                        <FavoriteIcon id="btnlikecm" />
                      </IconButton>
                      <Typography variant="body1" id="photousername">
                        {ele.likedCounter}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton>
                        <MessageIcon id="btnlikecm" />
                      </IconButton>
                      <Typography variant="body1" id="photousername">
                        {ele.commentCounter}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img alt="" src={jems} className="formimg" />

                      <Typography variant="body1" id="photousername">
                        {ele.noOfGems}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="photoendline" />
              </Box>
              <Box></Box>
            </Box>
          );
        })}
      </InfiniteScroll>
    </Box>
  );
}

export default Profile_view;
