import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./Model_shoutout.scss"
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebaseConfig/firebaseConfig';
import Spinners from "../../components/Spinner/Spinners";


function Model_shoutout_Rejected() {
  const [shoutOutRejected, setShoutOutRejected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getCompletedShotout();
  },[])

  const getCompletedShotout = () =>{
    setIsLoading(true);

    let q1 = query(collection(db,process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION),orderBy("requestedOn","desc"));
    getDocs(q1).then((rejectedShoutOut)=> {
      let allShoutOuts = [];
      rejectedShoutOut.forEach((shoutout)=>{
        allShoutOuts.push(shoutout.data());
      })
      setShoutOutRejected(allShoutOuts);
      setIsLoading(false);
    })
  }


  return (
    <Box sx={{padding:'0px', marginTop: "10px",display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center", height:"fit-content"}}>
       {isLoading ? <Spinners /> : null}
        {shoutOutRejected.map((val)=>{
                                  const dateTimeString2 = `${val.requestedOn.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit",hour12:true})} ${new Date(val.requestedOn.toDate()).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}` 

            return(
                <Box className="contant_box m_model_sh_cop_box1">
                 <Typography variant='body1' className='typography-loginpage'>{process.env.REACT_APP_REQUESTED_BY_TEXT} {val.requestedBy}</Typography>
                 <Typography variant='body1' className='typography-loginpage'>{process.env.REACT_APP_REQUESTED_ON_TEXT} {dateTimeString2}</Typography>
                 <Typography variant='body1' className='typography-loginpage'>{process.env.REACT_APP_MESSAGE_TEXT}:</Typography>
                 <Typography variant='body2' className='typography-loginpage' style={{margin:"10px 0 0 0"}}>{val.message}</Typography>
                </Box>
            )
        })}
       </Box>
  )
}

export default Model_shoutout_Rejected
