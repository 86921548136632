import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../../firebaseConfig/firebaseConfig";
import "./PaymentSucess.scss";
import { Typography } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Spinners from "../../components/Spinner/Spinners";

const PaymentFailed = () => {
  const navigate = useNavigate();
  let timeOut;
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [receivedObject, setReceivedObject] = useState([]);
  const [count, setCount] = useState(5);

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  let newData = searchParams.get("q");
  let data = JSON.parse(atob(newData));
  console.log(data);
  useEffect(() => {
    compareData();
  }, []);

  useEffect(() => {
    //Implementing the setInterval method
    if (!loading && count > 0) {
      const interval = setInterval(() => {
        setCount(count - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (count == 0) {
      window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK);
    }
    //Clearing the interval
  }, [count, loading]);
  const compareData = () => {
    let arr = [];
    let q1 = query(
      collection(db, process.env.REACT_APP_RECHARGE_GEMS_ADD_COLLECTION_NAME),
      where("paymentToken", "==", parseInt(data.token)),
      where("uid", "==", user.idUser)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        arr.push({ ...doc.data(), document_id: doc.id });
      });

      if (arr.length > 0) {
        arr.map(async (ele) => {
          await deleteDoc(
            doc(
              db,
              process.env.REACT_APP_RECHARGE_GEMS_ADD_COLLECTION_NAME,
              ele.document_id
            )
          );
        });
      }
      setLoading(false);
    });
    if (arr.length == 0) {
      setLoading(false);
    }
  };
  return (
    <div>
      {loading ? <Spinners /> : null}
      {!loading && (
        <div className="first">
          <div className="second">
            <div className="success2_1">
              <CloseRoundedIcon sx={{ fontSize: "10em", color: "white" }} />
            </div>
          </div>

          <Typography
            variant="h6"
            sx={{ mt: 3, color: "white" }}
            gutterBottom
            className="text-shadow-typography"
          >
            Unfortunetely, payment is not
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: 1, color: "white" }}
            gutterBottom
            className="text-shadow-typography"
          >
            successfully captured.
          </Typography>
          <Typography className="text-shadow-typography">
            Redirecting to home
          </Typography>
          <Typography>{count}</Typography>
        </div>
      )}
    </div>
  );
};

export default PaymentFailed;
