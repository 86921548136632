import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import copy from "copy-to-clipboard";  
const CopyMailPhoneNumberModal = (props) => {
  return (
    <Dialog
      open={true}
      onClose={() => props.close()}
      aria-labelledby="responsive-dialog-title"
      className="popup"
      style={{ justifyContent: "flex-start", alignItems: "start" }}
    >
      <DialogTitle
        className="dialog-title-background typography-loginpage"
        style={{ textAlign: "start" }}
      >
        User Details :
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          className="typography-loginpage"
          style={{ textAlign: "start", width: "100%",fontSize:"15px"}}
        >
          Name : {props.val?.user_name ? props.val.user_name : props.val.name}
        </DialogContentText>
        <DialogContentText
          className="typography-loginpage"
          style={{  textAlign: "start", width: "100%",fontSize:"15px"}}
        >
          Email : {props.val.email}
        </DialogContentText>
        <DialogContentText
          className="typography-loginpage"
          style={{  textAlign: "start", width: "100%",fontSize:"15px"}}
        >
          Mobile Number : {props.val.mobile}
        </DialogContentText>
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <Button
            className="typography-loginpage"
            onClick={() => props.close()}
            style={{
              width: "fit-content",
              padding: "0px 20px",
              marginBottom: "10px",
            }}
          >
            Ok
          </Button>
          <Button
            className="typography-loginpage"
            style={{
              width: "fit-content",
              padding: "0px 20px",
              marginBottom: "10px",
            }}
            onClick={() => {
            copy(props.val.email);
            props.close();
            props.toast(`${props.val.email} Copied`);
            }}
          >
            Copy Email
          </Button>
          <Button
            className="typography-loginpage"
            style={{ width: "fit-content", padding: "0px 20px" }}
            onClick={() => {
              copy(props.val.mobile);
              props.close();
              props.toast(`${props.val.mobile} Copied`);
            }}
          >
            Copy Mobile Number
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CopyMailPhoneNumberModal;
