import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import "./AddDM.scss";
import SearchIcon from "@mui/icons-material/Search";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";

function AddDM() {
  const location = useLocation();
  const [data, setData] = useState({ name: "", email: "" });
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const getUserByName = () => {
    if (data.name.trim() == "") {
      setText(process.env.REACT_APP_ENTER_USER_NAME_PLACEHOLDER);
      setOpen(true);
    } else {
      const q2 = query(
        collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
        where("name", "==", data.name.trimEnd())
      );
      getDocs(q2).then((result) => {
        let users = [];
        result.forEach((user) => {
          users.push(user.data());
        });
        setUser(users);
      });
    }
  };

  const getUserByEmail = () => {
    if (data.email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email) ==
      false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else {
      const q2 = query(
        collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
        where("email", "==", data.email.trim())
      );
      getDocs(q2).then((result) => {
        let users = [];
        result.forEach((user) => {
          users.push(user.data());
        });
        setUser(users);
      });
    }
  };
  return (
    <Box className="adddm">
      <Box className="AddDMmain">
        <Box className="AddDminputbox" style={{ marginBottom: "10px" }}>
          <TextField
            type="text"
            placeholder="Search with Name"
            variant="outlined"
            focused={false}
            id="myprofile-form-field"
            fullWidth
            size="small"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <Box className="searchbtnbox">
            <SearchIcon onClick={() => getUserByName()} />
          </Box>
        </Box>
        <Typography variant="body1" className="or">
          OR
        </Typography>
        <Box className="AddDminputbox">
          <TextField
            type="text"
            placeholder="Search with Email"
            variant="outlined"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            focused={false}
            id="myprofile-form-field"
            fullWidth
            size="small"
            style={{ marginTop: "10px", marginBottom: "15px" }}
          />
          <Box className="searchbtnbox">
            <SearchIcon onClick={() => getUserByEmail()} />
          </Box>
        </Box>
      </Box>
      <Box className="search_result_box" style={{ paddingTop: "46vh" }}>
        {user.map((singleuser) => {
          return (
            <Box
              className="search_result_boxa"
              onClick={() =>
                location.state.val == "dmpage"
                  ? navigate("/back/modalsideuserchats", {
                      state: { val: { userId: singleuser.idUser } },
                    })
                  : navigate("/back/modalsideuserhelpandsupportchats", {
                      state: { val: { userId: singleuser.idUser } },
                    })
              }
            >
              <Typography variant="body1" className="or">
                {singleuser.name}
              </Typography>
              <Typography variant="body1" className="or">
                {singleuser.email}
              </Typography>
              <Typography variant="body1" className="or">
                {singleuser.mobile}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <ModalComponent
        open={open}
        close={() => handleClose()}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </Box>
  );
}

export default AddDM;
