import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import modelprofilepic from "../../assests/videoCall.jpg";
import "./Model_live.scss";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from "@mui/icons-material/Image";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { updateEmail } from "firebase/auth";
import axios from "axios";
import Switch from "@mui/material/Switch";

function Model_live() {
  const [open, setOpen] = useState(false);
  const [liveData, setLiveData] = useState({});
  const [text, setText] = useState("");
  const [gemInput, setGemInput] = useState("");
  const [isCommentsEnabled, setIsCommentsEnabled] = useState(false);
  const [modalComponentOpen, setModalComponentOpen] = useState(false);
  const [sendNotificationData, setSendNotifiactonData] = useState({
    title: "",
    noOfGems: null,
  });

  const navigate = useNavigate();
  useEffect(() => {
    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData({ ...doc.data(), liveStrimingDocId: doc.id });
      });
    });
  }, []);

  const handleOpenStory = () => {
    navigate("/back/uploadstory");
  };

  const handleSendNotification = () => {
    console.log(gemInput, "GEMS");
    if (sendNotificationData.title.trim() == "") {
      setText(process.env.REACT_APP_PLEASE_ENTER_NOTIFICATION_MESAGE_TEXT);
      setModalComponentOpen(true);
    } else if (sendNotificationData.noOfGems == null) {
      setText(process.env.REACT_APP_PLEASE_ENTER_NO_OF_GEMS_FOR_LIVE_TEXT);
      setModalComponentOpen(true);
    } else if (isCommentsEnabled && gemInput.trim() === "") {
      setText("Please Enter Gems");
      setModalComponentOpen(true);
    } else {
      let sendNotifictionObject = {
        accessToken: "",
        accessTokenGeneratedOn: serverTimestamp(),
        channelName: "",
        createdOn: serverTimestamp(),
        instantLive: false,
        documentId: "",
        gemForUserComment: isCommentsEnabled ? parseInt(gemInput) : 0,
        enableCommentsInLiveStreaming: isCommentsEnabled ? true : false,
        liveSessionScheduledTime: serverTimestamp(),
        liveStatus: false,
        noOfGems: parseInt(sendNotificationData.noOfGems),
        state: process.env.REACT_APP_INITIAL_LIVE_STRIMING_STATE,
        title: sendNotificationData.title,
        uid: "",
      };
      addDoc(
        collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
        sendNotifictionObject
      ).then(() => {
        setOpen(false);
        setSendNotifiactonData({
          title: "",
          noOfGems: null,
        });
      });
    }
  };

  const handleOpenModalSide = () => {
    axios
      .get(
        process.env.REACT_APP_VIDEOCALL_ACCESSTOKEN_API.replaceAll(
          "{channelname}",
          process.env.REACT_APP_AGORA_CHANNEL_NAME
        )
      )
      .then((response) => {
        console.log("response", response);
        let objectforlive = {
          ...liveData,
          accessToken: response.data.token,
          liveStatus: true,
        };
        delete objectforlive.liveStrimingDocId;
        console.log("objectfor", objectforlive);
        updateDoc(
          doc(
            db,
            process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
            liveData.liveStrimingDocId
          ),
          { ...objectforlive }
        ).then(() => {
          objectforlive.liveStrimingDocId = liveData.liveStrimingDocId;
          navigate("/modallivestriming", {
            state: { liveData: objectforlive },
          });
        });
      });
  };

  return (
    <Box className="m_live">
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          width: "100vw",
          overflow: "hidden",
          position: "fixed",
          top: "62.33px",
          maxHeight: "50vh",
        }}
      >
        <img
          alt=""
          src={modelprofilepic}
          style={{ width: "100vw", height: "60vh" }}
        />
      </Box>
      <Box
        className="live_btn"
        style={{
          position: "fixed",
          top: "calc(50vh + 80px)",
          height: "-webkit-fill-avliable",
          width: "90vw",
        }}
      >
        {Object.keys(liveData).length > 0 && liveData?.state !== "finished" && (
          <Button
            variant="contained"
            className="m_live_btn typography-loginpage text-shadow-typography"
            onClick={() => handleOpenModalSide()}
          >
            <VideocamIcon className="live_f" />
            {process.env.REACT_APP_START_GROUP_LIVE_NOW_BUTTON}
          </Button>
        )}
        <Button
          variant="contained"
          className="m_live_btn typography-loginpage text-shadow-typography"
          onClick={() => setOpen(!open)}
        >
          <VideocamIcon className="live_f" />
          {process.env.REACT_APP_SEND_LIVE_NOTIFICATION_BUTTON}
        </Button>
        <Button
          variant="contained"
          className="m_live_btn typography-loginpage text-shadow-typography"
          onClick={() => handleOpenStory()}
        >
          <ImageIcon className="live_f" />
          {process.env.REACT_APP_SEND_LIVE_STORIES_BUTTON}
        </Button>
      </Box>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        className="popup_dialog"
      >
        <Box className="live_input">
          <TextField
            type="text"
            className="popup_feild"
            placeholder={process.env.REACT_APP_PLACEHOLDER_NOTIFICATON_MESSAGE}
            variant="outlined"
            focused={false}
            val={sendNotificationData.title}
            onChange={(e) =>
              setSendNotifiactonData({
                ...sendNotificationData,
                title: e.target.value,
              })
            }
            id="myprofile-form-field"
            fullWidth
            size="small"
            style={{ height: "70px" }}
          />
          <TextField
            type="number"
            placeholder={process.env.REACT_APP_PLACEHOLDER_NO_OF_GEMS_FOR_LIVE}
            variant="outlined"
            val={sendNotificationData.noOfGems}
            onChange={(e) =>
              setSendNotifiactonData({
                ...sendNotificationData,
                noOfGems: e.target.value,
              })
            }
            focused={false}
            id="myprofile-form-field"
            fullWidth
            size="small"
            style={{ height: "70px" }}
          />
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                color: "var(--black)",
                fontSize: "0.7em",
                fontFamily: "",
              }}
            >
              Enable live comments in live streaming{" "}
            </Typography>
            <Switch
              checked={isCommentsEnabled}
              onChange={() => setIsCommentsEnabled(!isCommentsEnabled)}
            />
          </Box>
          {isCommentsEnabled && (
            <TextField
              type="number"
              className="popup_feild"
              placeholder="Enter GEMS"
              variant="outlined"
              focused={false}
              value={gemInput}
              onChange={(e) => setGemInput(e.target.value)}
              id="myprofile-form-field"
              fullWidth
              size="small"
              style={{ height: "70px" }}
            />
          )}
          <Button
            variant="contained"
            className="m_l_btn"
            onClick={() => handleSendNotification()}
          >
            {process.env.REACT_APP_SEND_BUTTON}
          </Button>
        </Box>
      </Dialog>
      <ModalComponent
        open={modalComponentOpen}
        close={() => setModalComponentOpen(false)}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </Box>
  );
}

export default Model_live;
