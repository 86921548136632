import { Box, Grid, Typography, IconButton } from "@mui/material";
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import FavoriteIcon from "@mui/icons-material/Favorite";
import dimonds from "../../../assests/diamands.png";
import LockIcon from "@mui/icons-material/Lock";
import "../../../User/Videopage/Videopage.scss";
import "./HomeFeed.js";
import ModelProfilePicture from "../../../assests/ic_login1.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import play_icon from "../../../component/Images/play_icon.png";
import jems from "../../../component/Images/jems.png";
import { useNavigate } from "react-router-dom";

function ProfileViewVideo({ data, paginate, lastDoc }) {
  const navigate = useNavigate();
  return (
    <Box id="scrollListVideo" style={{ height: "100%", overflowY: "scroll" }}>
      <InfiniteScroll
        dataLength={Object.keys(data).length}
        next={() => {
          paginate(lastDoc);
        }}
        hasMore={true}
        scrollableTarget="scrollListVideo"
      >
        {Object.values(data).map((ele) => {
          // const isFree = ele.noOfGems == 0;
          // const isPurchased =
          //   myPurchedVideos.filter((pho) => pho.vdoid == ele.id)
          //     .length > 0;
          // const lockedStrip = !isFree
          //   ? `${
          //       !isPurchased
          //         ? process.env.REACT_APP_LOCKED_STRIP_TEXT
          //         : process.env.REACT_APP_UNLOCKED_STRIP_TEXT
          //     } Video`
          //   : false;

          return (
            <Box>
              <Grid container className="videodiv" columnSpacing={0}>
                <Grid item className="videodiv_video">
                  <div className="profilepic">
                    <img alt="" src={ModelProfilePicture} className="video" />
                  </div>
                </Grid>
                <Grid item className="videodiv_description">
                  <Typography variant="body1" id="videousername">
                    {process.env.REACT_APP_MODAL_NAME}
                  </Typography>
                  <Typography variant="body2" id="videocaption">
                    {ele.desc}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                className="postvideodiv"
                onClick={() =>
                  navigate("/back/updateVideo", { state: { videoobject: ele } })
                }
              >
                <Box className="postdivvideo">
                  <img alt="" src={ele.thumbUrl} className="postvideo" />
                </Box>

                <Box className="videolockcontant_video">
                  <img alt="" src={play_icon} id="playimg" />
                </Box>

                {ele.noOfGems > 0 ? (
                  <Box className="videoimlock">
                    <LockIcon
                      style={{ fontSize: "18px", marginRight: "2px" }}
                    />
                    <Typography variant="body2">Paid Video</Typography>
                  </Box>
                ) : null}
              </Box>

              <Grid container className="videolikediv">
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton>
                    <FavoriteIcon id="btnlikecmvideo" />
                  </IconButton>
                  <Typography variant="body1" id="videousername">
                    {ele.likedCounter}
                  </Typography>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton>
                    <MessageIcon id="btnlikecmvideo" />
                  </IconButton>

                  <Typography variant="body1" id="videousername">
                    {ele.commentCounter}
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img alt="" src={jems} className="formimg" />

                  <Typography variant="body1" id="photousername">
                    {ele.noOfGems}
                  </Typography>
                </Grid>
              </Grid>

              <Box className="photoendline" />
            </Box>
          );
        })}
      </InfiniteScroll>
    </Box>
    // <Box className="profile_view" id="scrollList" style={{ height: "100%", overflowY: "scroll" }}>
    //   <InfiniteScroll
    //     dataLength={Object.keys(data).length}
    //     next={() => {
    //       paginate(lastDoc);
    //     }}
    //     hasMore={true}
    //     scrollableTarget='scrollList'
    //   >
    //     {Object.values(data).map((item, index) => {
    //       return (
    //         <div key={index}>
    //           <Box className="photo_contant">
    //             <Grid container className="photodiv">
    //               <Grid item className="photodiv_imgdiv">
    //                 <img
    //                   alt="profilePhoto"
    //                   src={ModelProfilePicture}
    //                   className="photoimg"
    //                 />
    //               </Grid>
    //               <Grid item>
    //                 <Typography
    //                   variant="body1"
    //                   className="text-align-start"
    //                   id="photousername"
    //                 >
    //                   {item.title}
    //                 </Typography>
    //                 <Typography
    //                   variant="body2"
    //                   className="text-align-start"
    //                   id="photocaption"
    //                 >
    //                   {item.desc}
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //             <Box className="img_box">
    //               <img alt="photo" src={item.photoUrl} className="post_img" />
    //               {item.noOfGems != 0 ? (
    //                 <Typography
    //                   variant="body1"
    //                   className="typography-loginpage paid_text"
    //                 >
    //                   <LockIcon
    //                     style={{
    //                       fontSize: "18px",
    //                       marginRight: "4px",
    //                     }}
    //                   />
    //                   Paid Image
    //                 </Typography>
    //               ) : null}
    //             </Box>
    //             <Box className="img_text_box">
    //               <Typography
    //                 variant="body2"
    //                 className="typography-loginpage text"
    //               >
    //                 <FavoriteIcon className="img_text" />
    //                 {item.likedCounter}
    //               </Typography>
    //               <Typography
    //                 variant="body2"
    //                 className="typography-loginpage text"
    //               >
    //                 <MessageIcon className="img_text" />
    //                 {item.commentCounter}
    //               </Typography>
    //               <Typography
    //                 variant="body2"
    //                 className="typography-loginpage text"
    //               >
    //                 <img alt="" src={dimonds} style={{ height: "20px" }} />
    //                 {item.noOfGems}
    //               </Typography>
    //             </Box>
    //           </Box>
    //           <Box className="photoendline" />
    //         </div>
    //       );
    //     })}
    //   </InfiniteScroll>
    // </Box>
  );
}

export default ProfileViewVideo;
