import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Home.scss";
import "../../App.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomePageComponent from "../HomePageComponent/HomePageComponent";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  where,
  orderBy,
  query,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import LockIcon from "@mui/icons-material/Lock";
import jems from "../../component/Images/jems.png";
import { getAuth } from "firebase/auth";
import EmailPopup from "./EmailPopup";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [promotImage, setPromotImage] = useState(
    JSON.parse(
      localStorage.getItem(process.env.REACT_APP_PROMATEIMAGE_LOCALSTORAGE)
    )
  );
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );
  const [myPurchedPhotos, setMyPurchedPhotos] = useState(
    JSON.parse(
      localStorage.getItem(process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE)
    )
  );
  const [count, setCount] = useState(0);
  const [content, setContent] = useState(null);
  const [open, setOpen] = useState({ open: false, val: "" });
  const [purchaseModal, setPurchaseModal] = useState({ open: false });
  const [wallet, setWallet] = useState();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [otpKey, setOtpKey] = useState(location.state);


  useEffect(() => {
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
      // console.log(response.data(), "RESPONSE");
    });
  }, []);

  const handlePromoteImage = (ele) => {
    setPromotImage(ele);
    localStorage.setItem(process.env.REACT_APP_PROMATEIMAGE_LOCALSTORAGE, ele);
  };

  useEffect(() => {
    // debugger
    console.log(otpKey, "OTPKEY");
    console.log(location.state, "GOT STATE");
    // localStorage.setItem("otpKey", location.state);
    const q2 = doc(
      db,
      process.env.REACT_APP_USERS_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const userData = response?.data();

      if (!userData?.transfer) {
        console.log("first");
        localStorage.setItem("otp", otpKey);
        // const otpData = otpKey;
        // if (isFirstTime && otpKey === "password") {
        if (isFirstTime && otpKey === "phone") {
          const hasPopupBeenCanceled = localStorage.getItem("emailPopupShown");

          if (!hasPopupBeenCanceled) {
            setOpenPasswordDialog(true);
            // setOpenPasswordDialog(false);
            setIsFirstTime(false);
          }
        }
      }
    });
    // return () => {
    //   localStorage.removeItem("otpKey");
    // };
  }, [location.state, isFirstTime]);

  useEffect(() => {
    const promoteContent = query(
      collection(db, process.env.REACT_APP_PROMOTECONTENT_COLLECTION_NAME),
      orderBy("createdAt", "desc"),
      limit(1)
    );
    getDocs(promoteContent).then((contentForPromotion) => {
      contentForPromotion.forEach((doc) => {
        let isPurchased;
        if (testUser) {
          isPurchased = true;
        } else {
          isPurchased =
            myPurchedPhotos?.filter((ele) => ele.photoid == doc.data().itemId)
              .length > 0;
        }
        setContent({ ...doc.data(), isPurchased: isPurchased });
      });
    });
  }, [count]);

  const handlePurchase = (val) => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet?.uid)
    );

    getDocs(q2).then((result) => {
      console.log(result, "REsult");
      result.forEach((users) => {
        if (users.data()?.blocked) {
          console.log("TRUE");
          // navigate("/login");
          navigate("/login");
          localStorage.clear();
        } else {
          setCount(count + 1);
          let photoPurchase = {
            boughtOn: serverTimestamp(),
            photoUrl: val.itemPhotoUrl,
            photocreatedAt: val.createdAt,
            photodesc: val.desc,
            photoid: val.itemId,
            photomonitizeState:
              process.env.REACT_APP_UPLOAD_PHOTO_MONITIZE_STATE,
            photonoofgems: val.noOfGems,
            phototitle: val.itemTitle,
            platform: process.env.REACT_APP_PLATFORM,
            uid: auth.currentUser.uid,
            version: process.env.REACT_APP_VERSION,
          };
          let updatedWallet = {
            ...wallet,
            balance: wallet?.balance - val.noOfGems,
          };
          setWallet(updatedWallet);
          localStorage.setItem(
            process.env.REACT_APP_WALLET_LOCALSTORAGE,
            JSON.stringify(updatedWallet)
          );

          const querySnapshot = doc(
            db,
            process.env.REACT_APP_WALLET_COLLECTION_NAME,
            user.idUser
          );
          updateDoc(querySnapshot, {
            balance: updatedWallet.balance,
          }).then(() => { });
          setMyPurchedPhotos([...myPurchedPhotos, photoPurchase]);
          localStorage.setItem(
            process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
            JSON.stringify([...myPurchedPhotos, photoPurchase])
          );
          addDoc(
            collection(
              db,
              process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
            ),
            photoPurchase
          ).then((result) => {
            let photo_transaction_type_name =
              process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_NAME;
            let photo_transaction_type_id =
              process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_ID;
            let userTransactionnsTable = {
              dm_message_gif: "",
              dm_message_image: "",
              dm_message_text: "",
              live_session_purchase_gif_name: "",
              live_session_purchase_comment_message: "",
              live_session_ref_id: "",
              platform: process.env.REACT_APP_PLATFORM,
              purchased_media_description: photoPurchase.photodesc,
              purchased_media_title: photoPurchase.phototitle,
              purchased_media_url: photoPurchase.photoUrl,
              purchased_product_description: "",
              purchased_product_title: "",
              recharge_gems_transaction_amount: "",
              recharge_gems_transaction_id: "",
              transaction_gems: photoPurchase.photonoofgems,
              transaction_gems_string: `-${photoPurchase.photonoofgems}`,
              transaction_ref_id: photoPurchase.photoid,
              transaction_time: serverTimestamp(),
              transaction_type_id: photo_transaction_type_id,
              transaction_type_name: photo_transaction_type_name,
              updated_balance_after_transaction: `${updatedWallet.balance}`,
              user_email: user.email,
              user_id: user.idUser,
              user_name: user.name,
              version: process.env.REACT_APP_VERSION,
            };
            addDoc(
              collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
              userTransactionnsTable
            ).then(() => { });
          });
          setContent({ ...photoPurchase, isPurchased: true });
          console.log({ ...photoPurchase, isPurchased: true });
          console.log();
          handlePromoteImage(true);
          setPurchaseModal({ open: false, val: "" });
        }
      });
    });
  };

  return (
    <Box
      className="general"
    // onClick={() => promotImage ? handlePromoteImage(false) : null}
    >
      <Box className="homecss">
        <Box className="homepage">
          <HomePageComponent />
        </Box>
      </Box>
      {promotImage && content && (
        <Dialog
          open={promotImage}
          id="asd"
          PaperProps={{
            style: {
              borderRadius: "15px",
              border: "1px solid var(--white)",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            },
          }}
          fullWidth={false}
          fullScreen={false}
          onClose={() => handlePromoteImage(false)}
        >
          {content && (
            <Box className="imlock" style={{ left: 0 }}>
              {content && !content.isPurchased ? (
                <LockIcon
                  style={{
                    fontSize: "18px",
                    marginRight: "2px",
                  }}
                />
              ) : (
                <LockOpenIcon
                  style={{
                    fontSize: "18px",
                    marginRight: "2px",
                  }}
                />
              )}
              <Typography variant="body2">
                {!content.isPurchased ? "Locked Image" : "UnLocked Image"}
              </Typography>
            </Box>
          )}
          {content && (
            <img
              src={content ? content.itemPhotoUrl : null}
              style={{
                filter: !content.isPurchased ? "blur(10px)" : "",
                width: "100vw",
              }}
              onClick={() =>
                !content.isPurchased
                  ? setPurchaseModal({ open: true })
                  : setOpen({ open: true, val: content })
              }
            />
          )}
          {content && !content.isPurchased && (
            <Box className="lockcontant" style={{ width: "100%" }}>
              <LockIcon
                style={{
                  fontSize: "50px",
                  marginRight: "2px",
                }}
              />
              <Typography variant="body1">
                Unlock Photo at {content.noOfGems} GEMS
              </Typography>
            </Box>
          )}
        </Dialog>
      )}
      {open.open && content.isPurchased && (
        <Dialog fullScreen open={open.open} className="spop">
          <Box>
            <Box className="op">
              <Box className="mainop">
                <Box
                  className="backicon"
                  style={{ position: "absolute", top: "2%", zIndex: "2" }}
                  onClick={() => {
                    setOpen(false);
                    handlePromoteImage(true);
                  }}
                >
                  <ArrowBackIcon />
                </Box>
                <Box className="opBox">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={100}
                    defaultPositionY={200}
                  >
                    <TransformComponent
                      contentStyle={{ width: "100vw", height: "100vh" }}
                    >
                      <img
                        alt=""
                        src={open.val.itemPhotoUrl}
                        className="opimg"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}

      {openPasswordDialog && (
        <EmailPopup
          openDialog={openPasswordDialog}
          offPopup={() => {
            setOpenPasswordDialog(false);
            setIsFirstTime(false);
            localStorage.setItem("emailPopupShown", "false");
          }}
        />
      )}
      {purchaseModal.open && (
        <Dialog
          open={purchaseModal.open}
          onClose={() => setPurchaseModal({ open: false })}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          {content.noOfGems > wallet?.balance && (
            <>
              <DialogTitle id="modeltext" className="context-div">
                {process.env.REACT_APP_INSUFFICIENT_BALANCE}
              </DialogTitle>
              <Divider style={{ backgroundColor: "var(--white)" }} />
            </>
          )}

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>
          {content.noOfGems > wallet?.balance && (
            <DialogContent id="context">
              <DialogContentText className="context-div">
                <Typography variant="h6" id="modeltext">
                  {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
                  {content.noOfGems - wallet?.balance}
                </Typography>
                <img alt="" src={jems} className="jemsimg" />
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              onClick={() =>
                content.noOfGems > wallet?.balance
                  ? ""
                  : handlePurchase(content)
              }
              id="popbtn"
            >
              {content.noOfGems > wallet?.balance
                ? process.env.REACT_APP_RECHARGE_TEXT
                : `${process.env.REACT_APP_BUY_FOR_TEXT} ${content.noOfGems}`}
              <img alt="" src={jems} className="jemsimg" />
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                handlePromoteImage(true);
                setPurchaseModal({ open: false });
              }}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Home;
