import React, { useEffect, useState } from "react";
import "./ShoutoutmyMessages.scss";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Completed from "./Completed";
import Rejected from "./Rejected";
import { TabPanel } from "../../../components/Login/Tabelpanel";
import Today from "../../SuperFan/Today";
import Pending from "./Pending";

function ShoutoutmyMessages() {
  const [values, setValues] = useState(0);
  const navigate = useNavigate();
  const route = useLocation();
  const auth = getAuth();
  let isModal = JSON.parse(localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG));


  useEffect(()=>{
   window.scroll({behavior: "auto"})
  }, [])
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  return (
    <Box className="mymsgmain">
      <Box className="mymsg_head">
        <Typography variant="h6" className="mymsgtext">
          {process.env.REACT_APP_MY_MESSAGES_HEADING}
        </Typography>
        <hr style={{marginTop: "0px"}}/>
        <Box className="tab">
          <Tabs
            value={values}
            onChange={handleChange}
            className="tab-menu "
            aria-label="basic tabs example"
          >
            <Tab
              className="typography-loginpage"
              label="Pending"
              {...a11yProps(0)}
            />
            <Tab
              className="typography-loginpage"
              label="Completed"
              {...a11yProps(1)}
            />
            <Tab
              className="typography-loginpage"
              label="Rejected"
              {...a11yProps(2)}
            />
          </Tabs>
         
        </Box>
      </Box>
        <Box className="tabpanel">
            <TabPanel value={values} index={0} className='a'>
              <Pending />
            </TabPanel>
            <TabPanel value={values} index={1} className='a'>
              <Completed />
            </TabPanel>
            <TabPanel value={values} index={2} className='a'>
              <Rejected />
            </TabPanel>
          </Box>
    </Box>
  );
}

export default ShoutoutmyMessages;
