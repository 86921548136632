import { Box, Dialog, Typography } from "@mui/material";

import React, { useState } from "react";
import diamands from "../../assests/diamands.png";
import { DisplayImage, GifsData } from "../../configs/gifAssests";

const Gifs = (props) => {
  // const [gifData, setGifData] = useState();
  // console.log(gifData[0]?.noOfGems, "PROPSSS");

  return (
    <Dialog
      open={props.open}
      className="dm_gifs"
      maxWidth="200px"
      // onClose={() =>
      //   props.close({
      //     gif_id: 0,
      //     noOfGems: 0,
      //     name: "",
      //     image: "",
      //   })
      // }
      PaperProps={{
        sx: {
          position: "absolute",
          bottom: 0,
          m: 0,
          left: 0,
          width: "100%",
          backgroundColor: "var(--black)",
          color: "var(--white)",
          paddingTop: "20px",
          paddingBottom: "20px",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          overFlow: "scroll",
          backgroundColor: "var(--black)",
        }}
      >
        {GifsData.sort((a, b) => {
          return a.noOfGems - b.noOfGems;
        }).map((ele) => {
          // setGifData(ele);
          return (
            <Box
              style={{
                minWidth: "max-content",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginLeft: "12px",
                marginRight: "12px",
                borderRadius: "0px !important",
              }}
              onClick={() =>
                props.close({
                  gif_id: ele.gif_id,
                  noOfGems: ele.noOfGems,
                  name: ele.name,
                  image: ele.image,
                })
              }
            >
              <Typography
                style={{ fontFamily: "var(--font)", marginBottom: "2px" }}
              >
                {ele.name}
              </Typography>
              <Box
                style={{
                  width: "50px",
                  aspectRatio: "1/1",
                  borderRadius: "50%",
                }}
              >
                <DisplayImage
                  src={ele}
                  style={{
                    maxWidth: "50px",
                    maxHeight: "50px",
                    minWidth: "50px",
                    minHeight: "50px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Typography
                style={{
                  display: "flex",
                  fontFamily: "var(--font)",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2px",
                }}
              >
                <img src={diamands} style={{ width: "20px", height: "20px" }} />
                &nbsp;&nbsp;{ele.noOfGems}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Dialog>
  );
};

export default Gifs;
