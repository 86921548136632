import React, { useState, useEffect } from "react";
import "./Overview.scss";
import "../../../App.scss";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import OverViewCommon from "./OverViewCommon";

function Overview_Month() {
  const [photos, setPhotos] = useState("");

  const [videos, setVideos] = useState("");
  console.log(videos, "VIDEOS");
  const [liveRevenue, setLiveRevenue] = useState("");
  const [liveGifRevenue, setLiveGifRevenue] = useState("");
  const [liveComRevenue, setLiveComRevenue] = useState("");
  const [Dm, setDm] = useState("");
  const [videoCallPlaced, setVideoCallPlaced] = useState("");
  const [orders, setOrders] = useState("");
  const [shoutOut, setShoutOut] = useState("");
  const [user, setUser] = useState([]);

  const currentDate = new Date();

  // Get the current month and year
  const currentMonth = currentDate.toLocaleString("default", {
    month: "short",
  });
  //   const currentYear = currentDate.getFullYear();

  useEffect(() => {
    getTestUser();
  }, []);

  const getTestUser = async () => {
    let users = [];
    let userData = collection(
      db,
      `${process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME}/testUser/userData`
    );

    // Query data for the current month
    const querySnapshot = await getDocs(query(userData));

    querySnapshot.forEach((pic) => {
      users.push({ ...pic.data() });
    });

    setUser(users);
  };

  /***********************************
   *Usage: used for get all photos Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllphotos = async () => {
    let photos = [];
    let photoCollection = collection(
      db,
      process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
    );

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month
    const querySnapshot = await getDocs(
      query(
        photoCollection,
        where("boughtOn", ">=", startOfMonth),
        where("boughtOn", "<=", endOfMonth)
      )
    );

    querySnapshot.forEach((pic) => {
      photos.push({ ...pic.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filteredPhotos = photos.filter(
      (photo) => !userIdsToRemove.includes(photo.uid)
    );

    const totalPhotonoofgems = filteredPhotos?.reduce(
      (total, item) => total + item.photonoofgems,
      0
    );
    setPhotos(totalPhotonoofgems);
  };

  /***********************************
   *Usage: used for get all video Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllVideos = async () => {
    // debugger;
    let videos = [];
    let videoCollection = collection(
      db,
      process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
    );

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month based on boughtOn
    const querySnapshot = await getDocs(
      query(
        videoCollection,
        where("boughtOn", ">=", startOfMonth),
        where("boughtOn", "<=", endOfMonth)
      )
    );

    querySnapshot.forEach((vid) => {
      videos.push({ ...vid.data() });
    });

    console.log(user, "USER"); // It collects User ALL DATA
    const userIdsToRemove = user.map((user) => user.userId); // It makes array of users ID

    const filteredVideos = videos.filter((photo) => {
      // debugger;
      return !userIdsToRemove.includes(photo.uid);
    });

    const totalvideosGems = filteredVideos?.reduce(
      (total, item) => total + item.vdonoofgems,
      0
    );
    setVideos(totalvideosGems);
  };

  /***********************************
   *Usage: used for get all live stream Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllLiveStream = async () => {
    let lives = [];
    let liveCollection = collection(
      db,
      process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME
    );

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month based on liveSessionScheduledTime
    const querySnapshot = await getDocs(
      query(
        liveCollection,
        where("liveSessionScheduledTime", ">=", startOfMonth),
        where("liveSessionScheduledTime", "<=", endOfMonth)
      )
    );

    querySnapshot.forEach((liv) => {
      lives.push({ ...liv.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filteredLives = lives.filter(
      (photo) => !userIdsToRemove.includes(photo.uid)
    );

    // Initialize total variables with 0
    let totalliveRevenue = 0;
    let totalliveGifRevenue = 0;
    let totalliveComRevenue = 0;

    // Iterate through the filtered data and sum numeric values
    filteredLives.forEach((item) => {
      totalliveRevenue += parseFloat(item.liveRevenue) || 0;
      totalliveGifRevenue += parseFloat(item.liveGiphyRevenue) || 0;
      totalliveComRevenue += parseFloat(item.liveCommentRevenue) || 0;
    });

    // Set the parsed values to state
    setLiveRevenue(totalliveRevenue);
    setLiveGifRevenue(totalliveGifRevenue);
    setLiveComRevenue(totalliveComRevenue);
  };

  /***********************************
   *Usage: used for get all Dms Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllDirectMsg = async () => {
    let Dms = [];
    let dmCollection = collection(
      db,
      process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
    );

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month based on transaction_time
    const querySnapshot = await getDocs(
      query(
        dmCollection,
        where("transaction_time", ">=", startOfMonth),
        where("transaction_time", "<=", endOfMonth)
      )
    );

    querySnapshot.forEach((dm) => {
      Dms.push({ ...dm.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filteredDms = Dms.filter(
      (photo) => !userIdsToRemove.includes(photo.uid)
    );

    const findId = filteredDms.filter((data) => {
      return data.transaction_type_id === "7";
    });

    const totalDmGems = findId?.reduce(
      (total, item) => total + item.transaction_gems,
      0
    );
    setDm(totalDmGems);
  };

  /***********************************
   *Usage: used for get all video placed Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllVideoCallPlaced = async () => {
    let videoCallPlaces = [];
    let videoCallCollection = collection(
      db,
      process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME
    );

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month based on requestedAt
    const querySnapshot = await getDocs(
      query(
        videoCallCollection,
        where("requestedAt", ">=", startOfMonth),
        where("requestedAt", "<=", endOfMonth)
      )
    );

    querySnapshot.forEach((dm) => {
      videoCallPlaces.push({ ...dm.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filteredVideoCallPlaced = videoCallPlaces.filter(
      (photo) => !userIdsToRemove.includes(photo.uid)
    );

    const totalVideoCallGems = filteredVideoCallPlaced?.reduce(
      (total, item) => total + item.noOfGems,
      0
    );
    setVideoCallPlaced(totalVideoCallGems);
  };

  /***********************************
   *Usage: used for get all video placed Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllorderplaced = async () => {
    let orders = [];
    let order = collection(db, process.env.REACT_APP_ORDERS_COLLECTION);

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month based on requestedAt
    const querySnapshot = await getDocs(
      query(
        order,
        where("orderedOn", ">=", startOfMonth),
        where("orderedOn", "<=", endOfMonth)
      )
    );

    querySnapshot?.forEach((dm) => {
      orders?.push({ ...dm.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filteredOrders = orders.filter(
      (photo) => !userIdsToRemove.includes(photo.uid)
    );

    const totalorderGems = filteredOrders?.reduce(
      (total, item) => total + item.noOfGems,
      0
    );
    setOrders(totalorderGems);
  };

  /***********************************
   *Usage: used for get all shoutout Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllShoutOut = async () => {
    let shoutouts = [];
    let shoutout = collection(
      db,
      process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION
    );

    // Calculate the start and end dates for the current month
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Query data for the current month based on requestedAt
    const querySnapshot = await getDocs(
      query(
        shoutout,
        where("requestedOn", ">=", startOfMonth),
        where("requestedOn", "<=", endOfMonth)
      )
    );

    querySnapshot.forEach((dm) => {
      shoutouts.push({ ...dm.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filteredShoutOuts = shoutouts.filter(
      (photo) => !userIdsToRemove.includes(photo.uid)
    );

    const totalorderGems = filteredShoutOuts?.reduce(
      (total, item) => total + item.noOfGems,
      0
    );
    setShoutOut(totalorderGems);
  };

  useEffect(() => {
    getAllphotos();
    getAllVideos();
    getAllLiveStream();
    getAllDirectMsg();
    getAllVideoCallPlaced();
    getAllorderplaced();
    getAllShoutOut();
  }, [user]);

  return (
    <OverViewCommon
      photos={photos}
      orders={orders}
      Dm={Dm}
      videoCallPlaced={videoCallPlaced}
      shoutOut={shoutOut}
      liveRevenue={liveRevenue}
      videos={videos}
      liveComRevenue={liveComRevenue}
      liveGifRevenue={liveGifRevenue}
      income={currentMonth}
    />
  );
}

export default Overview_Month;
