import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../assests/app_logo.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Uploadphoto.css";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

function HelpandSupport() {
  const [data, setData] = useState({});
  const [lastDoc, setLastDoc] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState({});
  const navigate = useNavigate();
  const getData = (lastDoc = null) => {
    let quires = {};
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(db, process.env.REACT_APP_HELP_AND_SUPPORT_COLLECTION_NAME),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(db, process.env.REACT_APP_HELP_AND_SUPPORT_COLLECTION_NAME),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }
    const q = query();
    getDocs(q4).then((result) => {
      setLastDoc(result.docs[result.docs.length - 1]);
      result.forEach((doc) => {
        quires = { ...quires, [doc.id]: { ...doc.data(), docId: doc.id } };
      });
      setData({ ...data, ...quires });
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleModalClose = () => {
    setOpen(false);
    setSelectedQuery({});
  };
  const handleQueryResolved = () => {
    const updateQueryStatus = doc(
      db,
      process.env.REACT_APP_HELP_AND_SUPPORT_COLLECTION_NAME,
      `${selectedQuery.docId}`
    );
    setData({
      ...data,
      [selectedQuery.docId]: { ...data[selectedQuery.docId], status: true },
    });
    updateDoc(updateQueryStatus, {
      status: true,
    });
    handleModalClose();
  };

  return (
    <>
      <Box>
        <Box className="head">
          <Grid container rowSpacing={1} className="helpbox">
            <Grid item>
              <Box className="backbtn" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </Box>
            </Grid>
            <Grid item>
              <img alt="" src={logo} className="modalimg" />
            </Grid>
          </Grid>
          <Box className="line" />
          <Box id="heading">
            <Typography variant="h6" id="st">
              {process.env.REACT_APP_WALLET_QUERY_LIST_TEXT}
            </Typography>
          </Box>
          <Box className="cline" style={{ marginTop: 0 }} />
        </Box>
      </Box>
      <Box className="helpmain">
        <InfiniteScroll
          dataLength={Object.values(data).length}
          next={() => {
            getData(lastDoc);
          }}
          hasMore={true}
        >
          {Object.values(data).map((ele) => {
            const dateTimeString = `${ele.createdAt
              .toDate()
              .toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })} ${new Date(ele.createdAt.toDate()).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )}`;
            return (
              <Box
                className="helpdiv"
                style={{
                  backgroundColor: ele.status
                    ? "var(--green)"
                    : "var(--pinkDark)",
                }}
                onClick={() => {
                  if (!ele.status) {
                    setSelectedQuery(ele);
                    setOpen(true);
                  }
                }}
              >
                <Typography variant="body1" id="helptext">
                  {ele.name}&nbsp;
                  {process.env.REACT_APP_WALLET_HAS_SENT_QUERY_TEXT}
                </Typography>
                <Box className="cline" />
                <Typography variant="body1" id="helptext">
                  {process.env.REACT_APP_WALLET_EMAIL_TEXT}&nbsp;{ele.email}
                </Typography>
                <Typography variant="body1" id="helptext">
                  {process.env.REACT_APP_WALLET_MOBILE_TEXT}&nbsp;{ele.mobile}
                </Typography>
                <Typography variant="body1" id="helptext">
                  {ele.suject}
                </Typography>
                <Typography variant="body2" id="helptext1">
                  {ele.message}
                </Typography>
                <Typography variant="body2" id="helptext1">
                  {process.env.REACT_APP_WALLET_TIME_TEXT}:&nbsp;
                  {dateTimeString}
                </Typography>
              </Box>
            );
          })}
        </InfiniteScroll>
        <Box>
          <Button
            variant="contained"
            id="helpbtn"
            onClick={() => navigate("/userlistingforhelpandsupport")}
          >
            Help Chat
          </Button>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title "
        className="loguotpop"
      >
        <DialogTitle className="dialog-title-background typography-loginpage">
          {process.env.REACT_APP_DIALOG_APP_NAME}
        </DialogTitle>

        <DialogContent>
          <DialogContentText className="typography-loginpage">
            {process.env.REACT_APP_WALLET_QUERY_RESOLVED_TEXT}
          </DialogContentText>
          <Button
            className="typography-loginpage loginpage-button"
            onClick={() => handleQueryResolved()}
          >
            {process.env.REACT_APP_YES_BUTTON1}
          </Button>
          <Button
            className="typography-loginpage loginpage-button"
            onClick={() => handleModalClose()}
          >
            {process.env.REACT_APP_NO_BUTTON}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default HelpandSupport;
