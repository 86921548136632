import React from "react";
import { useNavigate } from "react-router-dom";
import Story from "../StoryPage/Story";
import Banner from "../BannerPage/Banner";
import Shop from "../ShopPage/Shop";
import LatestContentPage from "../LatestContentPage/LatestContentPage";
import TopPhotos from "../TopPhotosPage/TopPhotos";
import TopVideos from "../TopVideos/TopVideos";

const HomePageComponent = () => {
  const navigate = useNavigate();
  const handleClickHome = () => {
    navigate("/videos");
  };

  return (
    <>
      {/* <Story /> */}
      {/* <Banner /> */}
      <Shop />
      <LatestContentPage handleClickHome={() => handleClickHome()} />
      <TopPhotos />
      <TopVideos handleClickHome={() => handleClickHome()} />
    </>
  );
};

export default HomePageComponent;
