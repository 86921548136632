import { Box, Dialog, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../UserDMpage/UserDM.scss";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import diamands from "../../assests/diamands.png";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { RestrictedWords } from "../UserDMpage/RestrictedWords";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Spinners from "../../components/Spinner/Spinners";

function UserHelpandSupportDm() {
  const [lastDoc, setLastDoc] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInPutMessage] = useState("");
  const [sentMessage, setSendMessage] = useState({ open: false, val: "" });
  const [userLastMessageDoc, setUserLastMessageDoc] = useState();
  const [ModalDeviceToken, setModalDeviceToken] = useState("");
  const fileUpload = useRef();
  const [openImage, setOpenImage] = useState({ open: false, val: "" });
  const [openAbussiveRestrictor, setOpenAbussiveRestrictor] = useState({
    open: false,
    text: "",
  });
  const [loading, setIsLoding] = useState(false);

  useEffect(() => {
    getData();
    getFcmTokenOfModal();
    window.scrollTo({ behavior: "smooth" });
  }, []);

  const getFcmTokenOfModal = () => {
    let q1 = query(
      collection(db, process.env.REACT_APP_MODAL_DEVICE_FCM_TOKEN_COLLECTION),
      orderBy("fcmTokenTime", "desc"),
      limit(1)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        setModalDeviceToken(doc.data().deviceToken);
      });
    });
  };

  const getData = (lastDoc = null) => {
    onSnapshot(
      doc(
        db,
        process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
        `${user.idUser}`
      ),
      (doc) => {
        setUserLastMessageDoc(doc.data());
      }
    );

    let q4;
    if (lastDoc) {
      q4 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
          `${user.idUser}`,
          process.env.REACT_APP_MESSAGES_COLLECTION
        ),
        orderBy("sentAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
          `${user.idUser}`,
          process.env.REACT_APP_MESSAGES_COLLECTION
        ),
        orderBy("sentAt", "desc"),
        limit(15)
      );
    }

    onSnapshot(q4, (querySnapshot) => {
      let chats = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      setMessages([...messages, ...chats]);
    });
  };

  const handleSendMessages = (val) => {
    let isChat = val == "chat";
    let flag = false;

    RestrictedWords.map((ele) => {
      if (inputMessage.toLowerCase().includes(ele.toLowerCase())) {
        flag = true;
        setOpenAbussiveRestrictor({
          open: true,
          text: "You cannot use Bad Language",
        });
      }
    });
    if (
      (!flag && inputMessage.trim() !== "" && inputMessage.trim() !== ".jpg") ||
      typeof val === "object"
    ) {
      let obj = {
        chatMessage: inputMessage.replaceAll(".jpg", ""),
        isRead: false,
        noOfGems: parseInt(process.env.REACT_APP_NOOFGEMSFORHELPANDSUPPORT),
        platform: process.env.REACT_APP_PLATFORM,
        sentAt: Timestamp.fromDate(new Date()),
        sentBy: process.env.REACT_APP_DM_CHAT_SENT_BY_SENDER,
        version: process.env.REACT_APP_VERSION,
      };
      let updateLastMessageOfUser = {
        dpUrl: user.urlAvatar,
        fcmDeviceToken: ModalDeviceToken,
        lastChatMessage: obj.chatMessage,
        lastMessageTime: new Date(),
        name: user.name,
        chatAlreadyDone: true,
        totalUnreadCount: userLastMessageDoc.totalUnreadCount + 1,
      };
      if (isChat && inputMessage !== "") {
        setInPutMessage("");
        let newMessage = [...messages];
        newMessage.unshift(obj);
        setMessages(newMessage);
        setSendMessage({ open: false, val: "" });
        addDoc(
          collection(
            db,
            process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
            user.idUser,
            process.env.REACT_APP_MESSAGES_COLLECTION
          ),
          {
            ...obj,
            sentAt: serverTimestamp(),
          }
        ).then(() => {});
        const updateLastChat = doc(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
          user.idUser
        );

        updateDoc(updateLastChat, updateLastMessageOfUser).then(() => {});
      } else {
        setIsLoding(true);
        const formData = new FormData();
        formData.append("file", val.target.files[0]);
        let axiosConfig = {
          headers: {
            "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
            "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
            Authorization: process.env.REACT_APP_AUTHORIZATION,
            "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
            "Access-Control-Allow-Origin":
              process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
            Accept: process.env.REACT_APP_ACCEPT,
          },
        };
        axios
          .post(
            process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
            { file: val.target.files[0] },
            axiosConfig
          )
          .then((response) => {
            if (response.data.success) {
              let imgID = response.data.result.id;

              let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;
              obj = { ...obj, chatMessage: imagePath };
              setInPutMessage("");
              let newMessage = [...messages];
              newMessage.unshift(obj);
              setMessages(newMessage);
              updateLastMessageOfUser = {
                ...updateLastMessageOfUser,
                lastChatMessage: obj.chatMessage,
              };
              setSendMessage({ open: false, val: "" });
              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                  user.idUser,
                  process.env.REACT_APP_MESSAGES_COLLECTION
                ),
                {
                  ...obj,
                  sentAt: serverTimestamp(),
                }
              ).then(() => {});
              const updateLastChat = doc(
                db,
                process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                user.idUser
              );

              updateDoc(updateLastChat, updateLastMessageOfUser).then(() => {
                setIsLoding(false);
              });
            }
          });
      }
    }
  };

  return (
    <Box className="userdmpage">
      {loading ? <Spinners /> : null}
      <Box className="dm_head" style={{ zIndex: "200", position: "fixed" }}>
        <Typography variant="body1" className="dm_head_1">
          {process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_DISCLAIMER}
        </Typography>
        <Typography variant="body1" className="dm_head_2">
          {process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_WARNING.replaceAll(
            "{user.name}",
            `${user.name}`
          )}
        </Typography>
      </Box>

      <Box
        className="dm"
        id="dapage"
        style={{
          display: "flex",
          height: "calc(100% - 210px)",
          flexDirection: "column-reverse",
          overflow: "auto",
          position: "fixed",
          bottom: "75px",
          width: "100vw",
          zIndex: "100",
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            getData(lastDoc);
          }}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          scrollableTarget="dapage"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {messages.map((val, indexKey) => {
            let isMessageGif = val.chatMessage.includes(".gif");
            let isPhoto = val.chatMessage.includes("https://");
            let gifPhotoSrc;

            let dateTimeString;
            if (val.sentAt) {
              dateTimeString = `${val.sentAt
                .toDate()
                .toLocaleTimeString("en-IN", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour12: false,
                })}`
                .replaceAll("/", "-")
                .replaceAll(",", "");
            }

            return (
              <Box
                className={
                  val.sentBy === "sender" ? "sendmsgside" : "recivemsgside"
                }
              >
                <Box
                  className={
                    val.sentBy === "sender" ? "senddm_msgbox" : "recdm_msgbox"
                  }
                >
                  {!isMessageGif ? (
                    isPhoto ? (
                      <img
                        alt=""
                        style={{ width: "170px", aspectRatio: "1/1.5" }}
                        src={val.chatMessage}
                        id="msgimg"
                        onClick={() =>
                          setOpenImage({ open: true, val: val.chatMessage })
                        }
                        key={`${val.sentAt?.seconds}-${indexKey}-${val.sentAt?.nanoseconds}`}
                      />
                    ) : (
                      <Typography variant="body1">{val.chatMessage}</Typography>
                    )
                  ) : (
                    <img
                      alt=""
                      style={{ width: "170px", aspectRatio: "1/1.5" }}
                      src={gifPhotoSrc.image}
                      id="msgimg"
                    />
                  )}
                  <Typography
                    variant="body2"
                    style={{ fontSize: "10px", marginTop: "5px" }}
                  >
                    {val.sentAt !== null ? dateTimeString : null}
                  </Typography>
                </Box>
                {val.sentBy === "sender" ? (
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "var(--white)",
                      fontFamily: "var(--font)",
                      textShadow: "2px 1px 1px var(--black)",
                    }}
                  >
                    {process.env.REACT_APP_DM_CHAT_SPENT_MESSAGE}&nbsp;
                    {val.noOfGems} &nbsp;&nbsp;
                    <img
                      alt=""
                      src={diamands}
                      style={{ hight: "30px", width: "30px" }}
                    />
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            );
          })}
        </InfiniteScroll>
      </Box>
      <Box
        className="helpandsupporttextfieldbbackground"
        style={{
          width: "100vw",
          position: "fixed",
          bottom: "0px",
          height: "75px",
        }}
      >
        <Box
          className="dm_bottom"
          style={{ width: "90%", position: "fixed", bottom: "10px" }}
        >
          <TextField
            style={{ width: "75%" }}
            focused={false}
            value={inputMessage}
            onChange={(e) => setInPutMessage(e.target.value)}
            size="small"
            id="dm_bottm_textfild"
            sx={{ borderRadius: "18px !important" }}
            placeholder={process.env.REACT_APP_TYPE_YOUR_MESSAGE_PLACEHOLDER}
            inputProps={{ maxLength: 120 }}
          />
          <Box className="dma">
            <SendIcon onClick={() => handleSendMessages("chat")} />
          </Box>
          <Box className="dmb" onClick={() => fileUpload.current.click()}>
            <AttachFileIcon style={{ height: "90%" }} className="dmbimg" />
            <input
              hidden
              accept="image/*"
              ref={fileUpload}
              multiple
              type="file"
              onChange={(e) => handleSendMessages(e)}
            />
          </Box>
        </Box>
      </Box>
      {openImage.open && (
        <Dialog fullScreen open={openImage.open} className="spop">
          <Box>
            <Box className="op">
              <Box className="mainop">
                <Box
                  className="backicon"
                  style={{ position: "absolute", top: "2%", zIndex: "2" }}
                  onClick={() => setOpenImage({ open: false, val: "" })}
                >
                  <ArrowBackIcon />
                </Box>
                <Box className="opBox">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={100}
                    defaultPositionY={200}
                  >
                    <TransformComponent
                      contentStyle={{ width: "100vw", height: "100vh" }}
                    >
                      <img alt="" src={openImage.val} className="opimg" />
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
      <ModalComponent
        open={openAbussiveRestrictor.open}
        close={() => setOpenAbussiveRestrictor({ open: false, text: "" })}
        text={openAbussiveRestrictor.text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </Box>
  );
}

export default UserHelpandSupportDm;
