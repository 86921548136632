import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import diamands from "../../assests/diamands.png";
import "./UserVideoCall.scss";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";

function Book() {
  const navigate = useNavigate();

  const [videoCallPricing, setVideoCallPricing] = useState(0);
  useEffect(() => {
    getVideoCallPricing();
  }, []);
  const getVideoCallPricing = () => {
    let queryVideoCallPrices = query(
      collection(
        db,
        process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME,
        "videoCalls",
        "Pricing"
      )
    );
    getDocs(queryVideoCallPrices).then((videoCallPrices) => {
      let prices = [];
      videoCallPrices.forEach((element) => {
        prices.push({ ...element.data() });
      });
      console.log(prices, "prices");
      setVideoCallPricing(prices);
    });
  };
  const request = (time, gemsRequired, videoCallPrice) => {
    navigate("/mainback/sendrequestforvideocall", {
      state: {
        callDuration: time,
        gemsForCall: gemsRequired,
        specialRequestGems: videoCallPrice,
      },
    });
  };

  return (
    <Box>
      {videoCallPricing &&
        videoCallPricing
          .sort((a, b) => {
            return a.video_call_mins - b.video_call_mins;
          })
          .map((videoCallPrice) => {
            return (
              <Button
                variant="contained"
                fullWidth
                className="bookbtn button-text-align-start"
                onClick={() =>
                  request(
                    videoCallPrice.video_call_mins,
                    parseInt(videoCallPrice.video_call_gems),
                    videoCallPrice.video_call_specialrequest_gems
                  )
                }
              >
                {process.env.REACT_APP_MINS_VIDEO_CALL_TEXT.replace(
                  "5",
                  videoCallPrice.video_call_mins
                )}{" "}
                @ {parseInt(videoCallPrice.video_call_gems)}
                <img
                  alt=""
                  src={diamands}
                  style={{ height: "20px", marginLeft: "10px" }}
                />
              </Button>
            );
          })}
    </Box>
  );
}

export default Book;
