import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./Settingpage.scss";
import setting_icon from "../../assests/setting_icon.png";
import Fullpopup from "./Fullpopup";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { deleteUser, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Settingpage() {
  const [settingopen, setSettingopen] = useState(false);
  const [stext, setStext] = useState("");
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [openl, setOpenl] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const close = () => {
    setSettingopen(false);
  };

  const handleDeleteUser = async () => {
    await deleteDoc(doc(db, process.env.REACT_APP_WALLET_COLLECTION_NAME, `${currentUser.idUser}`)).then(
      (result) => {
      }
    );
    await deleteDoc(doc(db,process.env.REACT_APP_USERS_COLLECTION_NAME, `${currentUser.idUser}`)).then(
      (result) => {
      }
    );
    deleteUser(auth.currentUser)
      .then(() => {
        localStorage.clear();
      })
      .catch((error) => {
      });

    setOpenl(false);
    navigate("/modalpage");
  };

  return (
    <>
      <Box className="MyprofileHeadingMain">
        <Box className="MyprofileHeadingdiv">
          <img alt="" src={setting_icon} id="myprofileimg" />
          <Box className="mypvrline" />
          <Typography variant="h6" id="myprofileheading">
            {process.env.REACT_APP_SETTING_HEADING}
          </Typography>
        </Box>

        <Box className="mypline" />
      </Box>
      <Box className="setbox">
        <Box>
          <Button
            variant="outlined"
            id="setbtn"
            className="text-shadow-typography"
            onClick={() => {
              setSettingopen(!settingopen);
              setStext("Privacy-Pollicy");
            }}
          >
            {process.env.REACT_APP_PRIVACY_POLICY_BUTTON}
          </Button>
          <Button
            variant="outlined"
            id="setbtn"
            className="text-shadow-typography"
            onClick={() => {
              setSettingopen(!settingopen);
              setStext("Terms-Condition");
            }}
          >
            {process.env.REACT_APP_TERMS_CONDITION_BUTTON}
          </Button>
          <Button
            variant="outlined"
            id="deleteac"
            className="text-shadow-typography"
            onClick={() => setOpenl(true)}
          >
            {process.env.REACT_APP_DELETE_ACCOUNT_BUTTON}
          </Button>
        </Box>
        <Typography className="text-shadow-typography text-color" style={{width: "92%", textAlign:"center"}}>All your purchases, transactions and other data will be removed.</Typography>
        <Fullpopup close={close} open={settingopen} text={stext} />
        <Dialog
          open={openl}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
        >
          <DialogContent>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_DELETE_ACCOUNT_TEXT_MODAL}
            </DialogContentText>
            <DialogContentText className="typography-loginpage">
            {process.env.REACT_APP_DELETE_ACCOUNT_CONFIRMATION_TEXT_MODAL}
            </DialogContentText>{" "}
            <DialogContentText className="typography-loginpage">
             {process.env.REACT_APP_DELETE_ACCOUNT_WARNING_TEXT_MODAL}
            </DialogContentText>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_DELETE_ACCOUNT_NOTICE_TEXT_MODAL}
            </DialogContentText>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => handleDeleteUser()}
            >
              {process.env.REACT_APP_YES_BUTTON1}
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => setOpenl(false)}
            >
              {process.env.REACT_APP_NO_BUTTON}
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

export default Settingpage;
