import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import MyPurchase from "../../assests/my_purchase_icon.png";
import "./MyPurchase.scss";
import MyPurchasePhoto from "./MyPurchasePhoto";
import MyPurchaseOrder from "./MyPurchaseOrder";
import MyPurchaseVideo from "./MyPurchaseVideo";
import { TabPanel } from "../../components/Login/Tabelpanel";

function MyPurchasePage() {
  const [values, setValues] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  return (
    <>
      <Box className="myPurchasePageHeader">
        <Box
          className="MyprofileHeadingdiv"
          style={{
            position: "fixed",
            width: "100%",
            zIndex: "500",
            backgroundColor: " var(--pink)",
            marginTop: "9px",
          }}
        >
          <img alt="" src={MyPurchase} id="myprofileimg" />
          <Box className="mypvrline" />
          <Typography variant="h6" id="myprofileheading">
            {process.env.REACT_APP_MY_PURCHASES_TITLE}
          </Typography>
        </Box>
        {/* <Divider /> */}

        <Box className="tabboxnMypurchasePage" style={{ paddingTop: "0px" }}>
          <Box
            className="tab"
            style={{
              width: "100%",
              zIndex: "500",
              marginTop: "68px",
              borderTop: "2px solid white",
            }}
          >
            <Tabs
              value={values}
              onChange={handleChange}
              className="tab-menu "
              aria-label="basic tabs example"
            >
              <Tab
                className="typography-loginpage"
                label="Photos"
                {...a11yProps(0)}
              />
              <Tab
                className="typography-loginpage"
                label="Videos"
                {...a11yProps(1)}
              />
              <Tab
                className="typography-loginpage"
                label="Orders"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          <Box className="tabpanel">
            <TabPanel
              value={values}
              index={0}
              style={{ padding: "0px", backgroundColor: "var(--pinkDark)" }}
            >
              <MyPurchasePhoto />
            </TabPanel>
            <TabPanel
              value={values}
              index={1}
              style={{ backgroundColor: "var(--pinkDark)" }}
            >
              <MyPurchaseVideo />
            </TabPanel>
            <TabPanel
              value={values}
              index={2}
              style={{ backgroundColor: "var(--pink)" }}
            >
              <MyPurchaseOrder />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyPurchasePage;
