import React, { useState, useEffect } from "react";
import "./Overview.scss";
import "../../../App.scss";

import { db } from "../../../firebaseConfig/firebaseConfig";
import { collection, getDocs, query } from "firebase/firestore";
import OverViewCommon from "./OverViewCommon";

function Overview_All_Time() {
  const [photos, setPhotos] = useState("");
  const [videos, setVideos] = useState("");
  const [liveRevenue, setLiveRevenue] = useState("");
  const [liveGifRevenue, setLiveGifRevenue] = useState("");
  const [liveComRevenue, setLiveComRevenue] = useState("");
  const [Dm, setDm] = useState("");
  const [videoCallPlaced, setVideoCallPlaced] = useState("");
  const [orders, setOrders] = useState("");
  const [shoutOut, setShoutOut] = useState("");

  const [user, setUser] = useState([]);
  console.log(liveRevenue, liveGifRevenue, liveComRevenue, "===> liverevenue");
  /***********************************
   *Usage: react hook used for call user methods
   *param:{}
   *Author:spectus infotech
   ***********************************/
  useEffect(() => {
    getTestUser();
  }, []);

  /***********************************
   *Usage: react hook used for call all methods when user come
   *param:{}
   *Author:spectus infotech
   ***********************************/
  useEffect(() => {
    getAllphotos();
    getAllVideos();
    getAllLiveStream();
    getAllDirectMsg();
    getAllVideoCallPlaced();
    getAllorderplaced();
    getAllShoutOut();
  }, [user]);

  /***********************************
   *Usage: used for get all test user
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getTestUser = async () => {
    let users = [];
    let userData = collection(
      db,
      `${process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME}/testUser/userData`
    );

    const querySnapshot = await getDocs(query(userData));

    querySnapshot.forEach((pic) => {
      users.push({ ...pic.data() });
    });

    setUser(users);
  };

  /***********************************
   *Usage: used for get all photos Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllphotos = () => {
    // debugger;
    let photos = [];
    let photo = query(
      collection(db, process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME)
    );
    getDocs(photo).then((list) => {
      list.forEach((pic) => {
        photos.push({ ...pic.data() });
      });

      const userIdsToRemove = user.map((user) => user.userId);
      console.log(photos, "PHOTOS");
      const filterPhotos = photos?.filter(
        (photo) => !userIdsToRemove.includes(photo?.uid)
      );

      const totalPhotonoofgems = filterPhotos?.reduce(
        (total, item) => total + item.photonoofgems,
        0
      );

      setPhotos(totalPhotonoofgems);
    });
  };

  /***********************************
   *Usage: used for get all videos Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllVideos = () => {
    let videos = [];
    let video = query(
      collection(db, process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME)
    );
    getDocs(video).then((list) => {
      list.forEach((vid) => {
        videos.push({ ...vid.data() });
      });

      const userIdsToRemove = user.map((user) => user.userId);
      const filteredVideos = videos?.filter(
        (photo) => !userIdsToRemove.includes(photo?.uid)
      );

      const totalvideosGems = filteredVideos?.reduce(
        (total, item) => total + item.vdonoofgems,
        0
      );
      setVideos(totalvideosGems);
    });
  };

  /***********************************
   *Usage: used for get all live stream Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllLiveStream = () => {
    let lives = [];
    let live = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME)
    );

    getDocs(live).then((list) => {
      list.forEach((liv) => {
        lives.push({ ...liv.data() });
      });

      const userIdsToRemove = user.map((user) => user.userId);
      const filterLives = lives?.filter(
        (photo) => !userIdsToRemove.includes(photo?.uid)
      );

      // Initialize total variables with 0
      let totalliveRevenue = 0;
      let totalliveGifRevenue = 0;
      let totalliveComRevenue = 0;

      // Iterate through the filtered data and sum numeric values
      filterLives.forEach((item) => {
        totalliveRevenue += parseFloat(item.liveRevenue) || 0;
        totalliveGifRevenue += parseFloat(item.liveGiphyRevenue) || 0;
        totalliveComRevenue += parseFloat(item.liveCommentRevenue) || 0;
      });

      // Set the parsed values to state
      setLiveRevenue(totalliveRevenue);
      setLiveGifRevenue(totalliveGifRevenue);
      setLiveComRevenue(totalliveComRevenue);
    });
  };

  /***********************************
   *Usage: used for get all direct dm Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllDirectMsg = () => {
    let Dms = [];
    let dm = query(
      collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION)
    );
    getDocs(dm).then((list) => {
      list.forEach((dm) => {
        Dms.push({ ...dm.data() });
      });

      const findId = Dms.filter((data) => {
        return data.transaction_type_id == "7";
      });

      const userIdsToRemove = user.map((user) => user.userId);
      const filterDms = findId?.filter(
        (photo) => !userIdsToRemove.includes(photo?.uid)
      );

      const totalDmGems = filterDms?.reduce(
        (total, item) => total + item.transaction_gems,
        0
      );
      setDm(totalDmGems);
    });
  };

  /***********************************
   *Usage: used for get all video call placed Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllVideoCallPlaced = () => {
    let videoCallPlaces = [];
    let videoCall = query(
      collection(db, process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME)
    );
    getDocs(videoCall).then((list) => {
      list.forEach((dm) => {
        videoCallPlaces.push({ ...dm.data() });
      });

      const userIdsToRemove = user.map((user) => user.userId);
      const filteredVideoCallPlaced = videoCallPlaces?.filter(
        (photo) => !userIdsToRemove.includes(photo?.uid)
      );

      const totalDmGems = filteredVideoCallPlaced?.reduce(
        (total, item) => total + item.noOfGems,
        0
      );
      setVideoCallPlaced(totalDmGems);
    });
  };

  /***********************************
   *Usage: used for get all order placed Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllorderplaced = async () => {
    let orders = [];
    let order = collection(db, process.env.REACT_APP_ORDERS_COLLECTION);

    // Query all data without date filtering
    const querySnapshot = await getDocs(order);

    querySnapshot.forEach((dm) => {
      orders.push({ ...dm.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filterOrders = orders?.filter(
      (photo) => !userIdsToRemove.includes(photo?.uid)
    );

    const totalorderGems = filterOrders?.reduce(
      (total, item) => total + item.noOfGems,
      0
    );
    setOrders(totalorderGems);
  };

  /***********************************
   *Usage: used for get all shoutout placed Gems
   *param:{}
   *Author:spectus infotech
   ***********************************/
  const getAllShoutOut = async () => {
    let shoutouts = [];
    let shoutout = collection(
      db,
      process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION
    );

    // Query all data without date filtering
    const querySnapshot = await getDocs(shoutout);

    querySnapshot.forEach((dm) => {
      shoutouts.push({ ...dm.data() });
    });

    const userIdsToRemove = user.map((user) => user.userId);
    const filterShoutOut = shoutouts?.filter(
      (photo) => !userIdsToRemove.includes(photo?.uid)
    );

    const totalShoutOutGems = filterShoutOut?.reduce(
      (total, item) => total + item.noOfGems,
      0
    );
    setShoutOut(totalShoutOutGems);
  };

  /***********************************
   *Usage: return the ui
   *Author:spectus infotech
   ***********************************/
  return (
    <OverViewCommon
      photos={photos}
      orders={orders}
      Dm={Dm}
      videoCallPlaced={videoCallPlaced}
      shoutOut={shoutOut}
      liveRevenue={liveRevenue}
      videos={videos}
      liveComRevenue={liveComRevenue}
      liveGifRevenue={liveGifRevenue}
      income="all time"
    />
  );
}

export default Overview_All_Time;
