import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import jems from "../../component/Images/jems.png";
import "./Videopage.scss";
import { useNavigate } from "react-router-dom";

const ModalforPurchaseVideo = (props) => {
  console.log(props.val.val.noOfGems, "props.val.val.noOfGems==>");

  const navigate = useNavigate();
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      className="photopopup"
      aria-labelledby="draggable-dialog-title"
    >
      {props.val.val.noOfGems > props.wallet.balance && (
        <>
          <DialogTitle
            id="modeltext"
            className="context-div text-shadow-typograph"
          >
            Insufficient Gems
          </DialogTitle>
          <Divider style={{ backgroundColor: "var(--white)" }} />
        </>
      )}

      <DialogContent id="context" style={{ paddingBottom: "0px" }}>
        <DialogContentText className="context-div">
          <Typography
            variant="h6"
            id="modeltext"
            className="text-shadow-typograph"
          >
            Wallet balance: {props.wallet.balance}
          </Typography>
          <img alt="" src={jems} className="jemsimg" />
        </DialogContentText>
      </DialogContent>
      {props.val.val.noOfGems > props.wallet.balance && (
        <DialogContent id="context">
          <DialogContentText className="context-div">
            <Typography
              variant="h6"
              id="modeltext"
              className="text-shadow-typograph"
            >
              Need gems for: {props.val.val.noOfGems - props.wallet.balance}
            </Typography>
            <img alt="" src={jems} className="jemsimg" />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className="modelbtndiv">
        <Button
          variant="contained"
          onClick={() =>
            props.val.val.noOfGems > props.wallet.balance
              ? window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
              : props.handlePurchase(props.val.val)
          }
          id="popbtn"
          className="text-shadow-typograph"
        >
          {props.val.val.noOfGems > props.wallet.balance
            ? "Recharge"
            : `Buy for ${props.val.val.noOfGems}`}
          <img alt="" src={jems} className="jemsimg" />
        </Button>

        <Button
          variant="contained"
          onClick={() => props.close()}
          id="popbtn"
          className="text-shadow-typograph"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalforPurchaseVideo;
