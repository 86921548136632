import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Link,
  NavLink,
  Navigate,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

const PaymentPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "Vishal");
  const [data, setData] = useState(false);
  const [data1, setData1] = useState(false);

  const [currentData, setCurrentData] = useState({
    gems: "",
    price: "",
  });

  useEffect(() => {
    setCurrentData({
      gems: state.gems,
      price: state.price,
    });
  }, []);

  const notify = () => toast("+91-90049-40445 is Copied");
  const notify1 = () => toast("sktechnologic@okaxis is Copied");

  return (
    <div
      style={{
        paddingTop: "83px",
        backgroundColor: "var(--pink)",
        paddingLeft: "20px",
        fontFamily: "var(-font)",
      }}
    >
      <ToastContainer
        toastStyle={{ backgroundcolor: "var(--black)", color: "var(--black)" }}
        hideProgressBar="true"
        autoClose="2000"
      />
      <span
        style={{
          color: "var(--black)",
          fontSize: "27px",
          fontFamily: "var(-font)",
        }}
      >
        Recharge with &nbsp;
        <b style={{ color: "var(--black)", fontWeight: "bold" }}>
          {currentData.gems} gems
        </b>
      </span>
      <br></br>
      <span
        style={{ marginTop: "10px;", color: "var(--black)", fontSize: "27px" }}
      >
        Amount : <b style={{ fontWeight: "bold" }}>₹{currentData.price}</b>
      </span>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          color: "var(--black)",
        }}
      >
        <Typography
          style={{
            color: "var(--black)",
            fontSize: "15px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          UPI ID
        </Typography>

        <Link
          style={{
            color: "var(--black)",
            height: "3em",
            width: "93%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "9px",
            textDecoration: "none",
            justifyContent: "space-evenly",
            borderRadius: "10px",
            backgroundColor: "var(--black)",
          }}
        >
          <Typography style={{ fontSize: "24px" }}>
            sktechnologic@okaxis
          </Typography>
          <ContentCopyIcon
            onClick={() => {
              copy("sktechnologic@okaxis");
              notify1();
            }}
          />
        </Link>

        <Box style={{ width: "93%" }}>
          <Typography
            style={{
              fontSize: "17px",
              marginTop: "21px",
              color: "var(--black)",
            }}
          >
            Follow Below Steps to Recharge your wallet
          </Typography>

          <table style={{ color: "var(--black)" }}>
            <tr style={{ fontSize: "18px" }}>
              <td style={{ paddingBottom: "10px" }}>1.</td>
              <td style={{ paddingTop: "13px", fontFamily: "var(-font)" }}>
                Copy the above <b>UPI ID</b> and make the payment of mentioned
                amount.
              </td>
            </tr>
            <tr style={{ fontSize: "18px" }}>
              <td style={{ paddingBottom: "41px" }}>2.</td>
              <td style={{ paddingTop: "2px" }}>
                Take a screenshot of the payment success page where{" "}
                <strong>
                  Transaction ID and UPI Reference ID is Clearly visible{" "}
                </strong>
              </td>
            </tr>
            <tr style={{ fontSize: "18px" }}>
              <td style={{ paddingBottom: "41px" }}>3.</td>
              <td style={{ paddingTop: "2px" }}>
                Share the screenshot & email address (Used in app to login) to a{" "}
                <b>WhatsApp</b> number mentioned below.
              </td>
            </tr>
            <tr style={{ fontSize: "18px" }}>
              <td style={{ paddingBottom: "41px" }}>4.</td>
              <td style={{ paddingTop: "2px" }}>
                The Gems will be credited to your wallet <b>within 24 hours</b>{" "}
                from the time you've shared screenshot on whatsApp.
              </td>
            </tr>
          </table>

          <Typography
            style={{
              fontSize: "15px",
              color: "var(--black)",
              marginTop: "39px",
            }}
          >
            WhatsApp Payment Details to
          </Typography>
          <Link
            style={{
              color: "var(--black)",
              height: "3em",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "9px",
              textDecoration: "none",
              justifyContent: "space-evenly",
              borderRadius: "10px",
              backgroundColor: "green",
            }}
          >
            <Typography style={{ fontSize: "24px;" }}>
              +91-90049-40445
            </Typography>
            <ContentCopyIcon
              style={{ paddingLeft: "42px" }}
              onClick={() => {
                copy("+91-90049-40445");
                notify();
              }}
            />
            <WhatsAppIcon
              onClick={() => {
                window.open("http://wa.me/919004940445");
              }}
            />
          </Link>

          <Box
            style={{
              marginTop: "44px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                width: "40%",
                padding: "7px",
                borderRadius: "19px",
                backgroundColor: "#c90f0f",
              }}
              onClick={() => {
                window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK);
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentPage;
