import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "../../component/UploadVideo/Uploadvideo.scss";
import jems from "../../component/Images/jems.png";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import axios from "axios";
import Spinners from "../../components/Spinner/Spinners";
import { useLocation, useNavigate } from "react-router-dom";
import "./Model_shoutout.scss";

function ModelSendShoutoutVideo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [videoDescription, setVideoDescription] = useState({
    isPortrait: false,
    vdoUrl: "",
  });
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState({ vdoUrl: "", thumbUrl: "" });
  const videoUpload = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleVideoPublish = () => {
    if (videoDescription.vdoUrl == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_UPLOADVIDEO);
      setOpen(true);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      let axiosConfig = {
        headers: {
          "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
          "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
          Authorization: process.env.REACT_APP_AUTHORIZATION,
          "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
          "Access-Control-Allow-Origin":
            process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          Accept: process.env.REACT_APP_ACCEPT,
        },
      };
      let videopath = "";
      let webVdoUrl = "";

      axios
        .post(
          process.env.REACT_APP_VIDEO_UPLOAD_CLOUDFAIR_URL,
          { file: file.vdoUrl },
          axiosConfig
        )
        .then((response) => {
          let userrequest = location.state.val;
          if (response.data.success) {
            let uid = response.data.result.uid;
            videopath = `https://videodelivery.net/${uid}/manifest/video.m3u8`;
            webVdoUrl = `https://watch.videodelivery.net/${uid}`;
            let completeShoutout = {
              completedOn: serverTimestamp(),
              message: userrequest.message,
              noOfGems: userrequest.noOfGems,
              public: userrequest.public,
              isPortrait: videoDescription.isPortrait,
              requestedBy: userrequest.requestedBy,
              requestedOn: new Date(
                userrequest.requestedOn.seconds * 1000 +
                  userrequest.requestedOn.nanoseconds / 1000000
              ),
              tnc: userrequest.tnc,
              uid: userrequest.uid,
              userImage: userrequest.userImage,
              vdoUrl: videopath,
              webVdoUrl: webVdoUrl,
            };
            addDoc(
              collection(
                db,
                process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION
              ),
              completeShoutout
            ).then(() => {
              deleteDoc(
                doc(
                  db,
                  process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION,
                  userrequest.docId
                )
              ).then(() => {
                setIsLoading(false);
                navigate("/back/model_shoutout");
              });
            });
          }
        });
    }
  };

  const close = () => {
    setVideoDescription({
      thumbUrl: "",
      title: "",
      desc: "",
      noOfGems: "",
      isPortrait: false,
      vdoUrl: "",
    });
    setFile({ vdoUrl: "", thumbUrl: "" });
    setOpen(false);
  };

  const videopreview = (e) => {
    setFile({ ...file, vdoUrl: e.target.files[0] });
    setVideoDescription({
      ...videoDescription,
      vdoUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  return (
    <>
      <Box className="uvmain">
        <Box>
          <Box>
            <Typography variant="h6" id="uvdiv">
              {process.env.REACT_APP_SELECT_SHOUTOUT_VIDEO_FOR_TEXT}{" "}
              {location.state.val.requestedBy}
            </Typography>
          </Box>
          <Box id="line" />
          <Box className="vbtndiv">
            <Button
              variant="contained"
              id="btn"
              onClick={() => videoUpload.current.click()}
            >
              {process.env.REACT_APP_GALLARY_BUTTON_TEXT}
              <input
                hidden
                accept="mp4/*"
                ref={videoUpload}
                multiple
                type="file"
                onChange={(e) => videopreview(e)}
              />
            </Button>
          </Box>

          <Box className="vformdiv" style={{ paddingTop: "0px" }}>
            <Box id="line" />

            <Box className="vformimgdiv">
              {isLoading ? <Spinners /> : null}
              {videoDescription.vdoUrl !== "" ? (
                <Box className="boxvideo">
                  <video src={videoDescription.vdoUrl} className="img" />
                </Box>
              ) : (
                <Typography variant="body1" id="vformimgheadig">
                  {
                    process.env
                      .REACT_APP_UPLOAD_VIDEO_PAGE_NO_VIDEO_SELECTED_HEADING
                  }
                </Typography>
              )}
            </Box>
            <Box
              style={{
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Box
                className="contant_box m_model_sh_cop_box1"
                style={{ margin: "0px" }}
              >
                <Typography variant="body1" className="typography-loginpage">
                  {location.state.val.requestedBy}
                </Typography>

                <Typography
                  variant="body2"
                  className="typography-loginpage text-shadow-typography"
                  style={{ margin: "10px 0px", color: "var(--white)" }}
                >
                  {location.state.val.message}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid
                  item
                  xs={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={videoDescription.isPortrait}
                        onChange={(e) =>
                          setVideoDescription({
                            ...videoDescription,
                            isPortrait: e.target.checked,
                          })
                        }
                      />
                    }
                    label={process.env.REACT_APP_VIDEO_IS_IN_PORTRAIT_MODE_TEXT}
                    id="chek"
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    id="vpublishbtn"
                    onClick={() => handleVideoPublish()}
                  >
                    {process.env.REACT_APP_PUBLISH_BUTTON_TEXT}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
}
export default ModelSendShoutoutVideo;
