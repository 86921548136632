import React, { useState } from "react";
import "./Overview.scss";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../components/Login/Tabelpanel";
import Overview_Last_Month from "./Overview_Last_Month";
import Overview_Month from "./Overview_Month";
import Overview_All_Time from "./Overview_All_Time";

function Overview() {
  const [values, setValues] = useState(0);
  const [alignment, setAlignment] = useState(true);

  const handleAlignment = () => {
    setAlignment(!alignment);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  return (
    <Box className="m_over_home">
      <Box className="m_over_home_tab">
        <Tabs
          value={values}
          onChange={handleChange}
          className="tab-over-menu "
          aria-label="basic tabs example"
        >
          <Tab
            className="typography-loginpage"
            label="Last Month"
            {...a11yProps(0)}
          />
          <Tab
            className="typography-loginpage"
            label="Current Month"
            {...a11yProps(1)}
          />
          <Tab
            className="typography-loginpage"
            label="All Time"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <Box className="over_panel">
        <TabPanel value={values} index={0} className="a">
          <Overview_Last_Month />
        </TabPanel>
        <TabPanel value={values} index={1} className="a">
          <Overview_Month />
        </TabPanel>
        <TabPanel value={values} index={2} className="a">
          <Overview_All_Time />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default Overview;
