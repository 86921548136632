const Country1 = [
  { name: "Afghanistan", cca2: "AF", flag:"AF", code: "+93" },
  { name: "Angola", cca2: "AO", flag: "AO", code: "+244" },
  { name: "Albania", cca2: "AL", flag: "AL", code: "+355" },
  { name: "Andorra", cca2: "AD", flag: "AD", code: "+376" },
  { name: "United Arab Emirates", cca2: "AE", flag: "AE", code: "+971" },
  { name: "Argentina", cca2: "AR", flag: "AR", code: "+54" },
  { name: "Armenia", cca2: "AM", flag: "AM", code: "+374" },
  { name: "Antigua and Barbuda", cca2: "AG", flag: "AG", code: "+1268" },
  { name: "Australia", cca2: "AU", flag: "AU", code: "+61" },
  { name: "Austria", cca2: "AT", flag: "AT", code: "+43" },
  { name: "Azerbaijan", cca2: "AZ", flag: "AZ", code: "+994" },
  { name: "Burundi", cca2: "BI", flag: "BI", code: "+257" },
  { name: "Belgium", cca2: "BE", flag: "BE", code: "+32" },
  { name: "Benin", cca2: "BJ", flag: "BJ", code: "+229" },
  { name: "Burkina Faso", cca2: "BF", flag: "BF", code: "+226" },
  { name: "Bangladesh", cca2: "BD", flag: "BD", code: "+880" },
  { name: "Bulgaria", cca2: "BG", flag: "BG", code: "+359" },
  { name: "Bahrain", cca2: "BH", flag: "BH", code: "+973" },
  { name: "Bahamas", cca2: "BS", flag: "BS", code: "+1242" },
  { name: "Bosnia and Herzegovina", cca2: "BA", flag: "BA", code: "+387" },
  { name: "Belarus", cca2: "BY", flag: "BY", code: "+375" },
  { name: "Belize", cca2: "BZ", flag: "BZ", code: "+501" },
  { name: "Bolivia", cca2: "BO", flag: "BO", code: "+591" },
  { name: "Brazil", cca2: "BR", flag: "BR", code: "+55" },
  { name: "Barbados", cca2: "BB", flag: "BB", code: "+1246" },
  { name: "Brunei", cca2: "BN", flag: "BN", code: "+673" },
  { name: "Bhutan", cca2: "BT", flag: "BT", code: "+975" },
  { name: "Botswana", cca2: "BW", flag: "BW", code: "+267" },
  {
    name: "Central African Republic",
    cca2: "CF",
    flag: "CF",
    code: "+236"
  },
  { name: "Canada", cca2: "CA", flag: "CA", code: "+1" },
  { name: "Switzerland", cca2: "CH", flag: "CH", code: "+41" },
  { name: "Chile", cca2: "CL", flag: "CL", code: "+56" },
  { name: "China", cca2: "CN", flag: "CN", code: "+86" },
  { name: "Ivory Coast", cca2: "CI", flag: "CI", code: "+225" },
  { name: "Cameroon", cca2: "CM", flag: "CM", code: "+237" },
  { name: "DR Congo", cca2: "CD", flag: "CD", code: "+243" },
  { name: "Republic of the Congo", cca2: "CG", flag: "CG", code: "+242" },
  { name: "Colombia", cca2: "CO", flag: "CO", code: "+57" },
  { name: "Comoros", cca2: "KM", flag: "KM", code: "+269" },
  { name: "Cape Verde", cca2: "CV", flag: "CV", code: "+238" },
  { name: "Costa Rica", cca2: "CR", flag: "CR", code: "+506" },
  { name: "Cuba", cca2: "CU", flag: "CU", code: "+53" },
  { name: "Cyprus", cca2: "CY", flag: "CY", code: "+357" },
  { name: "Czechia", cca2: "CZ", flag: "CZ", code: "+420" },
  { name: "Germany", cca2: "DE", flag: "DE", code: "+49" },
  { name: "Djibouti", cca2: "DJ", flag: "DJ", code: "+253" },
  { name: "Dominica", cca2: "DM", flag: "DM", code: "+1767" },
  { name: "Denmark", cca2: "DK", flag: "DK", code: "+45" },
  { name: "Dominican Republic", cca2: "DO", flag: "DO", code: "+1809" },
  { name: "Algeria", cca2: "DZ", flag: "DZ", code: "+213" },
  { name: "Ecuador", cca2: "EC", flag: "EC", code: "+593" },
  { name: "Egypt", cca2: "EG", flag: "EG", code: "+20" },
  { name: "Eritrea", cca2: "ER", flag: "ER", code: "+291" },
  { name: "Spain", cca2: "ES", flag: "ES", code: "+34" },
  { name: "Estonia", cca2: "EE", flag: "EE", code: "+372" },
  { name: "Ethiopia", cca2: "ET", flag: "ET", code: "+251" },
  { name: "Finland", cca2: "FI", flag: "FI", code: "+358" },
  { name: "Fiji", cca2: "FJ", flag: "FJ", code: "+679" },
  { name: "France", cca2: "FR", flag: "FR", code: "+33" },
  { name: "Micronesia", cca2: "FM", flag: "FM", code: "+691" },
  { name: "Gabon", cca2: "GA", flag: "GA", code: "+241" },
  { name: "United Kingdom", cca2: "GB", flag: "GB", code: "+44" },
  { name: "Georgia", cca2: "GE", flag: "GE", code: "+995" },
  { name: "Ghana", cca2: "GH", flag: "GH", code: "+233" },
  { name: "Guinea", cca2: "GN", flag: "GN", code: "+224" },
  { name: "Gambia", cca2: "GM", flag: "GM", code: "+220" },
  { name: "Guinea-Bissau", cca2: "GW", flag: "GW", code: "+245" },
  { name: "Equatorial Guinea", cca2: "GQ", flag: "GQ", code: "+240" },
  { name: "Greece", cca2: "GR", flag: "GR", code: "+30" },
  { name: "Grenada", cca2: "GD", flag: "GD", code: "+1473" },
  { name: "Guatemala", cca2: "GT", flag: "GT", code: "+502" },
  { name: "Guyana", cca2: "GY", flag: "GY", code: "+592" },
  { name: "Honduras", cca2: "HN", flag: "HN", code: "+504" },
  { name: "Croatia", cca2: "HR", flag: "HR", code: "+385" },
  { name: "Haiti", cca2: "HT", flag: "HT", code: "+509" },
  { name: "Hungary", cca2: "HU", flag: "HU", code: "+36" },
  { name: "Indonesia", cca2: "ID", flag: "ID", code: "+62" },
  { name: "India", cca2: "IN", flag: "IN", code: "+91" },
  { name: "Ireland", cca2: "IE", flag: "IE", code: "+353" },
  { name: "Iran", cca2: "IR", flag: "IR", code: "+98" },
  { name: "Iraq", cca2: "IQ", flag: "IQ", code: "+964" },
  { name: "Iceland", cca2: "IS", flag: "IS", code: "+354" },
  { name: "Israel", cca2: "IL", flag: "IL", code: "+972" },
  { name: "Italy", cca2: "IT", flag: "IT", code: "+39" },
  { name: "Jamaica", cca2: "JM", flag: "JM", code: "+1876" },
  { name: "Jordan", cca2: "JO", flag: "JO", code: "+962" },
  { name: "Japan", cca2: "JP", flag: "JP", code: "+81" },
  { name: "Kazakhstan", cca2: "KZ", flag: "KZ", code: "+76" },
  { name: "Kenya", cca2: "KE", flag: "KE", code: "+254" },
  { name: "Kyrgyzstan", cca2: "KG", flag: "KG", code: "+996" },
  { name: "Cambodia", cca2: "KH", flag: "KH", code: "+855" },
  { name: "Kiribati", cca2: "KI", flag: "KI", code: "+686" },
  { name: "Saint Kitts and Nevis", cca2: "KN", flag: "KN", code: "+1869" },
  { name: "South Korea", cca2: "KR", flag: "KR", code: "+82" },
  { name: "Kuwait", cca2: "KW", flag: "KW", code: "+965" },
  { name: "Laos", cca2: "LA", flag: "LA", code: "+856" },
  { name: "Lebanon", cca2: "LB", flag: "LB", code: "+961" },
  { name: "Liberia", cca2: "LR", flag: "LR", code: "+231" },
  { name: "Libya", cca2: "LY", flag: "LY", code: "+218" },
  { name: "Saint Lucia", cca2: "LC", flag: "LC", code: "+1758" },
  { name: "Liechtenstein", cca2: "LI", flag: "LI", code: "+423" },
  { name: "Sri Lanka", cca2: "LK", flag: "LK", code: "+94" },
  { name: "Lesotho", cca2: "LS", flag: "LS", code: "+266" },
  { name: "Lithuania", cca2: "LT", flag: "LT", code: "+370" },
  { name: "Luxembourg", cca2: "LU", flag: "LU", code: "+352" },
  { name: "Latvia", cca2: "LV", flag: "LV", code: "+371" },
  { name: "Morocco", cca2: "MA", flag: "MA", code: "+212" },
  { name: "Monaco", cca2: "MC", flag: "MC", code: "+377" },
  { name: "Moldova", cca2: "MD", flag: "MD", code: "+373" },
  { name: "Madagascar", cca2: "MG", flag: "MG", code: "+261" },
  { name: "Maldives", cca2: "MV", flag: "MV", code: "+960" },
  { name: "Mexico", cca2: "MX", flag: "MX", code: "+52" },
  { name: "Marshall Islands", cca2: "MH", flag: "MH", code: "+692" },
  { name: "Macedonia", cca2: "MK", flag: "MK", code: "+389" },
  { name: "Mali", cca2: "ML", flag: "ML", code: "+223" },
  { name: "Malta", cca2: "MT", flag: "MT", code: "+356" },
  { name: "Myanmar", cca2: "MM", flag: "MM", code: "+95" },
  { name: "Montenegro", cca2: "ME", flag: "ME", code: "+382" },
  { name: "Mongolia", cca2: "MN", flag: "MN", code: "+976" },
  { name: "Mozambique", cca2: "MZ", flag: "MZ", code: "+258" },
  { name: "Mauritania", cca2: "MR", flag: "MR", code: "+222" },
  { name: "Mauritius", cca2: "MU", flag: "MU", code: "+230" },
  { name: "Malawi", cca2: "MW", flag: "MW", code: "+265" },
  { name: "Malaysia", cca2: "MY", flag: "MY", code: "+60" },
  { name: "Namibia", cca2: "NA", flag: "NA", code: "+264" },
  { name: "Niger", cca2: "NE", flag: "NE", code: "+227" },
  { name: "Nigeria", cca2: "NG", flag: "NG", code: "+234" },
  { name: "Nicaragua", cca2: "NI", flag: "NI", code: "+505" },
  { name: "Netherlands", cca2: "NL", flag: "NL", code: "+31" },
  { name: "Norway", cca2: "NO", flag: "NO", code: "+47" },
  { name: "Nepal", cca2: "NP", flag: "NP", code: "+977" },
  { name: "Nauru", cca2: "NR", flag: "NR", code: "+674" },
  { name: "New Zealand", cca2: "NZ", flag: "NZ", code: "+64" },
  { name: "Oman", cca2: "OM", flag: "OM", code: "+968" },
  { name: "Pakistan", cca2: "PK", flag: "PK", code: "+92" },
  { name: "Panama", cca2: "PA", flag: "PA", code: "+507" },
  { name: "Peru", cca2: "PE", flag: "PE", code: "+51" },
  { name: "Philippines", cca2: "PH", flag: "PE", code: "+63" },
  { name: "Palau", cca2: "PW", flag: "PW", code: "+680" },
  { name: "Papua New Guinea", cca2: "PG", flag: "PG", code: "+675" },
  { name: "Poland", cca2: "PL", flag: "PL", code: "+48" },
  { name: "North Korea", cca2: "KP", flag: "KP", code: "+850" },
  { name: "Portugal", cca2: "PT", flag: "PT", code: "+351" },
  { name: "Paraguay", cca2: "PY", flag: "PY", code: "+595" },
  { name: "Qatar", cca2: "QA", flag: "QA", code: "+974" },
  { name: "Romania", cca2: "RO", flag: "RO", code: "+40" },
  { name: "Russia", cca2: "RU", flag: "RU", code: "+7" },
  { name: "Rwanda", cca2: "RW", flag: "RW", code: "+250" },
  { name: "Saudi Arabia", cca2: "SA", flag: "SA", code: "+966" },
  { name: "Sudan", cca2: "SD", flag: "SD", code: "+249" },
  { name: "Senegal", cca2: "SN", flag: "SN", code: "+221" },
  { name: "Singapore", cca2: "SG", flag: "SG", code: "+65" },
  { name: "Solomon Islands", cca2: "SB", flag: "SB", code: "+677" },
  { name: "Sierra Leone", cca2: "SL", flag: "SL", code: "+232" },
  { name: "El Salvador", cca2: "SV", flag: "SV", code: "+503" },
  { name: "San Marino", cca2: "SM", flag: "SM", code: "+378" },
  { name: "Somalia", cca2: "SO", flag: "SO", code: "+252" },
  { name: "Serbia", cca2: "RS", flag: "RS", code: "+381" },
  { name: "South Sudan", cca2: "SS", flag: "SS", code: "+211" },
  { name: "São Tomé and Príncipe", cca2: "ST", flag: "ST", code: "+239" },
  { name: "Suriname", cca2: "SR", flag: "SR", code: "+597" },
  { name: "Slovakia", cca2: "SK", flag: "SK", code: "+421" },
  { name: "Slovenia", cca2: "SI", flag: "SI", code: "+386" },
  { name: "Sweden", cca2: "SE", flag: "SE", code: "+46" },
  { name: "Swaziland", cca2: "SZ", flag: "SZ", code: "+268" },
  { name: "Seychelles", cca2: "SC", flag: "SC", code: "+248" },
  { name: "Syria", cca2: "SY", flag: "SY", code: "+963" },
  { name: "Chad", cca2: "TD", flag: "TD", code: "+235" },
  { name: "Togo", cca2: "TG", flag: "TG", code: "+228" },
  { name: "Thailand", cca2: "TH", flag: "TH", code: "+66" },
  { name: "Tajikistan", cca2: "TJ", flag: "TJ", code: "+992" },
  { name: "Turkmenistan", cca2: "TM", flag: "TM", code: "+993" },
  { name: "Timor-Leste", cca2: "TL", flag: "TL", code: "+670" },
  { name: "Tonga", cca2: "TO", flag: "TO", code: "+676" },
  { name: "Trinidad and Tobago", cca2: "TT", flag: "TT", code: "+1868" },
  { name: "Tunisia", cca2: "TN", flag: "TN", code: "+216" },
  { name: "Turkey", cca2: "TR", flag: "TR", code: "+90" },
  { name: "Tuvalu", cca2: "TV", flag: "TV", code: "+688" },
  { name: "Tanzania", cca2: "TZ", flag: "TZ", code: "+255" },
  { name: "Uganda", cca2: "UG", flag: "UG", code: "+256" },
  { name: "Ukraine", cca2: "UA", flag: "UA", code: "+380" },
  { name: "Uruguay", cca2: "UY", flag: "UY", code: "+598" },
  { name: "United States", cca2: "US", flag: "US", code: "+1" },
  { name: "Uzbekistan", cca2: "UZ", flag: "UZ", code: "+998" },
  { name: "Vatican City", cca2: "VA", flag: "VA", code: "+3906698" },
  {
    name: "Saint Vincent and the Grenadines",
    cca2: "VC",
    flag: "VC",
    code: "+1784"
  },
  { name: "Venezuela", cca2: "VE", flag: "VE", code: "+58" },
  { name: "Vietnam", cca2: "VN", flag: "VN", code: "+84" },
  { name: "Vanuatu", cca2: "VU", flag: "VU", code: "+678" },
  { name: "Samoa", cca2: "WS", flag: "WS", code: "+685" },
  { name: "Yemen", cca2: "YE", flag: "YE", code: "+967" },
  { name: "South Africa", cca2: "ZA", flag: "ZA", code: "+27" },
  { name: "Zambia", cca2: "ZM", flag: "ZM", code: "+260" },
  { name: "Zimbabwe", cca2: "ZW", flag: "ZW", code: "+263" }
]

export default Country1;