import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import React, { useEffect, useState } from "react";
import "./Opimg.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinners from "../../components/Spinner/Spinners";
import Comment from "../commentpage/Commentpage";
import { getAuth } from "firebase/auth";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Opimg(props) {
  console.log(props, "Opimg");

  const auth = getAuth();
  const [likes, setLikes] = useState(0);
  const [comment, setComment] = useState(0);
  const [loading, setLoading] = useState(false);
  const [likedPhoto, setLikedPhoto] = useState(props.val.myLiked);
  const [openComment, setopComment] = useState({ open: false, val: "" });
  console.log(openComment, "openComment");

  const [commentCounter, setCommentCounter] = useState(
    props.val.commentCounter
  );
  const [likeCounter, setLikeCounter] = useState(props.val.likedCounter);

  const handleCommentCount = () => {
    setCommentCounter(commentCounter + 1);
  };

  const getdata = async () => {
    setLoading(true);
    const q1 = query(
      collection(
        db,
        process.env.REACT_APP_PHOTOS_COLLECTION,
        props.val.id,
        process.env.REACT_APP_LIKED_COLLECTION
      )
    );
    console.log(q1, "q1");

    const querysnapsort1 = await getDocs(q1);
    const liked = [];
    querysnapsort1.forEach((doc) => {
      liked.push(doc.data());
    });

    setLikes(liked.length);
    const q3 = query(
      collection(
        db,
        process.env.REACT_APP_PHOTOS_COLLECTION,
        props.val.id,
        process.env.REACT_APP_LIKED_COLLECTION
      ),
      where("userId", "==", auth.currentUser.uid)
    );
    const querysnapsort3 = await getDocs(q3);
    if (!querysnapsort3.empty) {
      setLikedPhoto(true);
    }
    const q2 = query(
      collection(
        db,
        process.env.REACT_APP_PHOTOS_COMMENTS_COLLECTION,
        props.val.id,
        process.env.REACT_APP_COMMENTS_COLLECTION
      )
    );
    const querysnapsort2 = await getDocs(q2);
    const comments = [];
    querysnapsort2.forEach((doc) => {
      comments.push(doc.data());
    });
    console.log(comments, "comments");

    setComment(comments.length);
    setLoading(false);
  };
  useEffect(() => {
    if (props.open) {
      if (props.from == "topphotos") {
        getdata().then(() => {
          setLoading(false);
        });
      }
    }
  }, [props.val.id]);

  const closeComment = () => {
    setopComment(false);
  };

  const handleTopPhotosCommentCount = () => {
    setComment(comment + 1);
  };

  const handleLikesIcon = () => {
    setLikeCounter(likeCounter + 1);
    setLikedPhoto(true);
    if (props.from !== "topphotos") {
      props.likeHandling(props.val);
    } else {
      let obj = {
        userId: auth.currentUser.uid,
      };
      setLikes(likes + 1);
      addDoc(
        collection(
          db,
          process.env.REACT_APP_PHOTOS_COLLECTION,
          `${props.val.id}`,
          process.env.REACT_APP_LIKED_COLLECTION
        ),
        obj
      ).then(() => { });
    }
  };

  return (
    <>
      {props.open ? (
        <>
          <Dialog fullScreen open={props.open} className="spop">
            <Box>
              {loading ? <Spinners /> : ""}
              <Box className="op">
                <Box className="mainop">
                  <Box
                    className="backicon"
                    style={{ position: "absolute", top: "2%", zIndex: "2" }}
                    onClick={props.close}
                  >
                    <ArrowBackIcon />
                  </Box>
                  <Box className="opBox">
                    <TransformWrapper
                      defaultScale={1}
                      defaultPositionX={100}
                      defaultPositionY={200}
                    >
                      <TransformComponent
                        contentStyle={{ width: "100vw", height: "100vh" }}
                      >
                        <img
                          alt=""
                          src={props.val.photoUrl}
                          className="opimg"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Box>

                  <Box
                    className="lcBox"
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      bottom: "10px",
                    }}
                  >
                    <p
                      style={{
                        color: "var(--white)",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      {props.val.desc}
                    </p>
                    <Grid container className="photolikediv">
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton>
                          {likedPhoto ? (
                            <FavoriteIcon
                              id="btnlikecm"
                              style={{ color: "var(--red)" }}
                            />
                          ) : (
                            <FavoriteIcon
                              id="btnlikecm"
                              onClick={() => handleLikesIcon()}
                            />
                          )}
                        </IconButton>

                        <Typography variant="body1" id="photousername">
                          {props.from == "topphotos" ? likes : likeCounter}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={async () =>
                            await setopComment({ open: true, val: props.val })
                          }
                        >
                          <MessageIcon id="btnlikecm" />
                        </IconButton>
                        <Typography variant="body1" id="photousername">
                          {props.from == "topphotos" ? comment : commentCounter}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
            {console.log(openComment.val, "commentprops")}
            <Comment
              open={openComment.open}
              close={() => closeComment()}
              addCommentCount={(ele) => props.addCommentCount(ele)}
              val={openComment.val}
              from="opimg"
              handleCommentCountPhotosPage={() => handleCommentCount()}
              handleCommentCountTopPhotosPage={() =>
                handleTopPhotosCommentCount()
              }
            />
          </Dialog>
        </>
      ) : null}
    </>
  );
}

export default Opimg;
