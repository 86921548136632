import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  where,
  limit,
  orderBy,
  query,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import PlayIcon from "../../assests/play_icon.png";
import "./LatestContent.scss";
import { useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Spinners from "../Spinner/Spinners";
import Opimg from "../../User/Opimg/Opimg";
import ModalforPurchase from "../../User/Photopage/ModalforPurchase";
import { getAuth } from "firebase/auth";
import ModalforPurchaseVideo from "../../User/Videopage/ModalforPurchaseVideo";

const LatestContentPage = () => {
  const auth = getAuth();
  const [topContent, setTopContent] = useState([]);
  const [topContentImg, setTopContentImg] = useState([]);
  const [topContentVideo, setTopContentVideo] = useState([]);
  const [myPurchedPhotos, setMyPurchedPhotos] = useState(
    JSON.parse(
      localStorage.getItem(
        process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
      )
    )
  );
  const [myPurchedVideos, setMyPurchedVideos] = useState(
    JSON.parse(
      localStorage.getItem(
        process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
      )
    )
  );
  const [open, setOpen] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );

  const [loading, setLoading] = useState(true);
  const [openPhoto, setOpenPhoto] = useState({ open: false, val: "" });
  const [VideoOpen, setVideoOpen] = useState({ open: false, val: "" });
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );
  useEffect(() => {
    getData();
    setLoading(false);

    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
      // console.log(response.data(), "RESPONSE");
    });
  }, []);

  useEffect(() => {
    let topContent1 = topContent;
    topContent1 = [...topContent1, ...topContentVideo, ...topContentImg];
    topContent1.sort(function (x, y) {
      return y.createdAt - x.createdAt;
    });
    console.log(topContent1, "topContent1===>");

    setTopContent(topContent1);
  }, [topContentImg, topContentVideo]);
  const getData = () => {
    const q6 = query(
      collection(db, process.env.REACT_APP_PHOTOS_COLLECTION),
      orderBy("createdAt", "desc"),
      limit(18)
    );
    getDocs(q6).then((result) => {
      const tmpTopContentImg = [];
      result.forEach((doc) => {
        tmpTopContentImg.push({ ...doc.data(), id: doc.id });
      });
      setTopContentImg(tmpTopContentImg);
    });
    const q7 = query(
      collection(db, process.env.REACT_APP_VIDEOS_COLLECTION),
      orderBy("createdAt", "desc"),
      limit(18)
    );
    getDocs(q7).then((data) => {
      const tmpTopContentVideo = [];
      data.forEach((doc) => {
        tmpTopContentVideo.push({ ...doc.data(), id: doc.id });
      });
      setTopContentVideo(tmpTopContentVideo);
    });
  };
  const navigate = useNavigate();
  const handleMediaClick = (ele, isVideo, isPurchased, isFree) => {
    if (isFree) {
      if (isVideo) {
        navigate("/videos", { state: { val: ele } });
      } else {
        setOpenPhoto({ open: true, val: ele });
      }
      return;
    } else if (!isFree && isPurchased) {
      if (isVideo) {
        navigate("/videos", { state: { val: ele } });
      } else {
        setOpenPhoto({ open: true, val: ele });
      }
      return;
    }
    return;
  };
  const closePhoto = () => {
    setOpenPhoto(false);
  };

  const handlePurchase = (val) => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet?.uid)
    );

    getDocs(q2).then((result) => {
      console.log(result, "REsult");
      result.forEach((users) => {
        if (users.data()?.blocked) {
          console.log("TRUE");
          // navigate("/login");
          navigate("/login");
          localStorage.clear();
        } else {
          let photoPurchase = {
            boughtOn: serverTimestamp(),
            photoUrl: val.photoUrl,
            photocreatedAt: val.createdAt,
            photodesc: val.desc,
            photoid: val.id,
            photomonitizeState: "",
            photonoofgems: val.noOfGems,
            phototitle: val.title,
            platform: process.env.REACT_APP_PLATFORM,
            uid: auth.currentUser.uid,
            version: process.env.REACT_APP_VERSION,
          };
          let updatedWallet = {
            ...wallet,
            balance: wallet?.balance - val.noOfGems,
          };
          setWallet(updatedWallet);
          localStorage.setItem(
            process.env.REACT_APP_WALLET_LOCALSTORAGE,
            JSON.stringify(updatedWallet)
          );

          const querySnapshot = doc(
            db,
            process.env.REACT_APP_WALLET_COLLECTION_NAME,
            user.idUser
          );
          updateDoc(querySnapshot, {
            balance: updatedWallet.balance,
          }).then(() => { });
          setMyPurchedPhotos([...myPurchedPhotos, photoPurchase]);
          localStorage.setItem(
            process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
            JSON.stringify([...myPurchedPhotos, photoPurchase])
          );
          addDoc(
            collection(
              db,
              process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
            ),
            photoPurchase
          ).then((result) => {
            let photo_transaction_type_name =
              process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_NAME;
            let photo_transaction_type_id =
              process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_ID;
            let userTransactionnsTable = {
              dm_message_gif: "",
              dm_message_image: "",
              dm_message_text: "",
              live_session_purchase_gif_name: "",
              live_session_purchase_comment_message: "",
              live_session_ref_id: "",
              platform: process.env.REACT_APP_PLATFORM,
              purchased_media_description: photoPurchase.photodesc,
              purchased_media_title: photoPurchase.phototitle,
              purchased_media_url: photoPurchase.photoUrl,
              purchased_product_description: "",
              purchased_product_title: "",
              recharge_gems_transaction_amount: "",
              recharge_gems_transaction_id: "",
              transaction_gems: photoPurchase.photonoofgems,
              transaction_gems_string: `-${photoPurchase.photonoofgems}`,
              transaction_ref_id: photoPurchase.photoid,
              transaction_time: serverTimestamp(),
              transaction_type_id: photo_transaction_type_id,
              transaction_type_name: photo_transaction_type_name,
              updated_balance_after_transaction: `${updatedWallet.balance}`,
              user_email: user.email,
              user_id: user.idUser,
              user_name: user.name,
              version: process.env.REACT_APP_VERSION,
            };
            addDoc(
              collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
              userTransactionnsTable
            ).then(() => { });
          });

          setOpen({ open: false, val: "" });
        }
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVideoPurchase = (val) => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet.uid)
    );

    getDocs(q2).then((result) => {
      console.log(result, "REsult");
      result.forEach((users) => {
        if (users.data()?.blocked) {
          console.log("TRUE");
          // navigate("/login");
          navigate("/login");
          localStorage.clear();
        } else {
          let videoPurchase = {
            boughtOn: serverTimestamp(),
            thumbUrl: val.thumbUrl,
            vdoUrl: val.vdoUrl,
            vdocreatedAt: val.createdAt,
            vdodesc: val.desc,
            vdoid: val.id,
            vdomonitizeState: "",
            vdonoofgems: val.noOfGems,
            vdotitle: val.title,
            platform: process.env.REACT_APP_PLATFORM,
            uid: auth.currentUser.uid,
            version: process.env.REACT_APP_VERSION,
          };
          let updatedWallet = {
            ...wallet,
            balance: wallet?.balance - val.noOfGems,
          };
          setWallet(updatedWallet);
          localStorage.setItem(
            process.env.REACT_APP_WALLET_LOCALSTORAGE,
            JSON.stringify(updatedWallet)
          );

          const querySnapshot = doc(
            db,
            process.env.REACT_APP_WALLET_COLLECTION_NAME,
            user.idUser
          );
          updateDoc(querySnapshot, {
            balance: updatedWallet.balance,
          }).then(() => { });
          setMyPurchedVideos([...myPurchedVideos, videoPurchase]);
          localStorage.setItem(
            process.env.REACT_APP_PURCHASEDVIDEOS_LOCALSTORAGE,
            JSON.stringify([...myPurchedVideos, videoPurchase])
          );
          addDoc(
            collection(
              db,
              process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
            ),
            videoPurchase
          ).then((result) => {
            let VideoPurchased_transaction_type_name =
              process.env.REACT_APP_VDO_TRANSACTION_TYPE_NAME;
            let VideoPurchased_transaction_type_id =
              process.env.REACT_APP_VDO_TRANSACTION_TYPE_ID;
            let userTransactionnsTable = {
              dm_message_gif: "",
              dm_message_image: "",
              dm_message_text: "",
              live_session_purchase_gif_name: "",
              live_session_purchase_comment_message: "",
              live_session_ref_id: "",
              platform: process.env.REACT_APP_PLATFORM,
              purchased_media_description: videoPurchase.vdodesc,
              purchased_media_title: videoPurchase.vdotitle,
              purchased_media_url: videoPurchase.vdoUrl,
              purchased_product_description: "",
              purchased_product_title: "",
              recharge_gems_transaction_amount: "",
              recharge_gems_transaction_id: "",
              transaction_gems: videoPurchase.vdonoofgems,
              transaction_gems_string: `-${videoPurchase.vdonoofgems}`,
              transaction_ref_id: videoPurchase.vdoid,
              transaction_time: serverTimestamp(),
              transaction_type_id: VideoPurchased_transaction_type_id,
              transaction_type_name: VideoPurchased_transaction_type_name,
              updated_balance_after_transaction: `${updatedWallet.balance}`,
              user_email: user.email,
              user_id: user.idUser,
              user_name: user.name,
              version: process.env.REACT_APP_VERSION,
            };
            addDoc(
              collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
              userTransactionnsTable
            ).then(() => { });
          });

          setVideoOpen({ open: false, val: "" });
        }
      });
    });
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  return loading ? (
    <Spinners />
  ) : topContent?.length > 0 ? (
    <Box className="latest-content1">
      <Typography className="typography-loginpage">
        {process.env.REACT_APP_LATEST_CONTENT_TITLE_TEXT}
      </Typography>
      <div className="rec-carousel">
        <div className="rec-carousel-item-latest-content">
          <div className="jZpChr">
            <div className="events">
              {topContent.map((ele, index) => {
                let isFree;
                if (testUser) {
                  isFree = true;
                } else {
                  isFree = ele.noOfGems == 0;
                }
                let isPurchased;
                if (testUser) {
                  isPurchased = true;
                } else {
                  isPurchased = (myPurchedVideos || myPurchedPhotos) &&
                    [...myPurchedVideos, ...myPurchedPhotos]?.filter(
                      (pho) => pho.vdoid == ele.id || pho.photoid == ele.id
                    ).length > 0;
                }
                const isVideo = ele.hasOwnProperty("thumbUrl");
                const lockedImage = !isVideo && !isPurchased && !isFree;
                const locakedVdo = isVideo && !isPurchased && !isFree;
                const lockedStrip = !isFree
                  ? `${!isPurchased
                    ? process.env.REACT_APP_LOCKED_STRIP_TEXT
                    : process.env.REACT_APP_UNLOCKED_STRIP_TEXT
                  } ${isVideo ? "Video" : "Image"}`
                  : false;
                return (
                  <div
                    className="items"
                    onClick={() =>
                      handleMediaClick(ele, isVideo, isPurchased, isFree)
                    }
                  >
                    {lockedStrip && (
                      <div className="strip-text typography-loginpage page-label">
                        {!isPurchased ? (
                          <LockIcon style={{ fontSize: "12px" }} />
                        ) : (
                          <LockOpenIcon style={{ fontSize: "12px" }} />
                        )}
                        &nbsp; {lockedStrip}
                      </div>
                    )}
                    {isVideo && (
                      <div
                        className="video-play-icon"
                        onClick={() =>
                          locakedVdo
                            ? setVideoOpen({ open: true, val: ele })
                            : null
                        }
                      >
                        <img src={PlayIcon} id="playicon" />
                      </div>
                    )}
                    {lockedImage && (
                      <div
                        className="lockedImageIcon"
                        onClick={() => setOpen({ open: true, val: ele })}
                      >
                        <LockIcon />
                      </div>
                    )}
                    <img
                      style={{
                        filter: lockedImage ? "blur(10px)" : "",
                      }}
                      className={lockedImage ? "locked-image" : ""}
                      src={ele?.thumbUrl || ele?.photoUrl}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {open.open && (
        <ModalforPurchase
          open={open.open}
          val={open}
          wallet={wallet}
          close={() => handleClose()}
          handlePurchase={(ele) => handlePurchase(ele)}
        />
      )}
      {VideoOpen.open && (
        <ModalforPurchaseVideo
          open={VideoOpen.open}
          val={VideoOpen}
          wallet={wallet}
          close={() => handleVideoClose()}
          handlePurchase={(ele) => handleVideoPurchase(ele)}
        />
      )}
      {openPhoto.open && (
        <Opimg
          open={openPhoto.open}
          close={() => closePhoto()}
          val={openPhoto.val}
          from="topphotos"
        />
      )}
    </Box>
  ) : null;
};

export default LatestContentPage;
