import {
  Box,
  Dialog,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect, useState } from "react";
import "./commentpage.scss";
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useLocation } from "react-router-dom";
import Spinners from "../../components/Spinner/Spinners";
import Backhead from "../Headers/BackHead/Backhead";

function Comment(props) {
  console.log(props.val, "commentprops");

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [user, setUser] = useState();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);

  useEffect(() => {
    getdata().then(() => {
      setFlag(flag + 1);
    });
    setLoading(false);
  }, [props.val, count]);

  const getdata = async () => {
    let q2;
    setUser(
      await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
      )
    );
    if (props.val.hasOwnProperty("vdoUrl")) {

      q2 = await query(
        collection(
          db,
          process.env.REACT_APP_VIDEOS_COMMENTS_COLLECTION,
          props.val.id,
          process.env.REACT_APP_COMMENTS_COLLECTION
        ),
        orderBy("createdAt", "desc")
      );
    } else {
      q2 = await query(
        collection(
          db,
          process.env.REACT_APP_PHOTOS_COMMENTS_COLLECTION,
          props.val.id,
          process.env.REACT_APP_COMMENTS_COLLECTION
        ),
        orderBy("createdAt", "desc")
      );
    }

    const querysnapsort2 = await getDocs(q2);
    const comment = [];
    querysnapsort2.forEach((doc) => {
      doc && comment.push(doc.data());
    });
    setComments(comment);
  };

  const handleAdd = async (val) => {
    const obj = {
      commentString: newComment,
      createdAt: serverTimestamp(),
      name: user.name,
      platform: process.env.REACT_APP_PLATFORM,
      urlAvatar: user.urlAvatar,
      userId: user.idUser,
      version: process.env.REACT_APP_VERSION,
    };
    setNewComment("");
    let commentsManage = [obj];
    if (newComment.trim() !== "") { setComments(commentsManage.concat(comments)); }
    if (props.val.hasOwnProperty("vdoUrl") && newComment.trim() !== "") {
      await addDoc(
        collection(
          db,
          process.env.REACT_APP_VIDEOS_COMMENTS_COLLECTION,
          props.val.id,
          process.env.REACT_APP_COMMENTS_COLLECTION
        ),
        obj
      ).then(() => {
        setCount(count + 1);
      });
    } else {
      if (newComment.trim() !== "") {
        await addDoc(
          collection(
            db,
            process.env.REACT_APP_PHOTOS_COMMENTS_COLLECTION,
            props.val.id,
            process.env.REACT_APP_COMMENTS_COLLECTION
          ),
          obj
        ).then(() => {
          setCount(count + 1);
        });
      }

    }

    if (props.from == "opimg" && newComment.trim() !== "") {
      props.handleCommentCountPhotosPage();
      props.handleCommentCountTopPhotosPage();
    }
    if ((props.from == "photospage" || props.from == "opimg") && newComment.trim() !== "") {
      props.addCommentCount(props.val);
    }
  };

  return props.open ? (
    <Dialog fullScreen open={props.open} className="spop">
      <>
        {loading === true ? <Spinners /> : ""}
        <Backhead close={() => props.close()} />
        <Box className="addcommentdiv">
          <TextField
            placeholder="Add your Comment"
            id="addfield"
            size="small"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Box className="sendbox">
                    <SendIcon
                      style={{ color: "var(--white) !important" }}
                      onClick={() => handleAdd(props.val)}
                    />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          className={
            comments.length > 0 ? "maincommentdiv" : "no-comment maincommentdiv"
          }
        >
          {comments.length === 0 && flag !== 0 ? (
            <Typography className="no-comment text-shadow-typography">
              No Comment added yet!
            </Typography>
          ) : null}
          {comments.map((ele) => {
            return (
              <Grid
                container
                className="commentdivp"
                style={{ justifyContent: "space-between" }}
              >
                <Grid item className="commentdivp_img">
                  <div className="commentprofile">
                    <img
                      alt=""
                      src={
                        ele.urlAvatar === null
                          ? process.env.REACT_APP_PLACEHOLDER_IMAGE_URL
                          : ele.urlAvatar
                      }
                      className="pimg"
                    />
                  </div>
                </Grid>
                <Grid item className="commentdivp_cm">
                  <Typography variant="body1" id="cmmsg">
                    {ele.name}
                  </Typography>
                  <Typography ma variant="body2" id="cmmsg">
                    {ele.commentString}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </>
    </Dialog>
  ) : null;
}

export default Comment;
